/* eslint-disable no-unused-vars */
import {
  action, computed, observable, runInAction, toJS, makeObservable,
} from 'mobx';
import { ContentType, Language, ProductType, SearchCategory } from '../constants/options';
import { GetLanguage } from '../utilities/general';
import { Axios } from '../utilities/network';
import RootStore from './root-store';


export default class ProductStore {

  loading: boolean = false;

  loadingModal: boolean = false;

  bannerList: any = [];

  promotionList: any = [];

  popularList: any = [];

  newPromotionList: any = [];

  // Product List Page
  loadingList: boolean = false;
  productListBySearchKey: any = [];
  productListByCategory: any = [];
  listFilterItems: any = [];
  // End Product List Page

  // Product Detail Page
  loadingDateRange: boolean = false;
  loadingPriceOptions: boolean = false;
  checkingAva: boolean = false;
  productDetail: any | null = null;
  productDateRange: string[] = [];
  priceOptions: any | null = null;
  avaInfo: any | null = null;
  // End Product Detail Page

  // Travel Planning Page
  productListByTPBooking: any = [];
  groupListByBooking: any = [];
  // End Travel Planning Page

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      loading: observable,
      bannerList: observable,
      promotionList: observable,
      popularList: observable,
      newPromotionList: observable,
      // Product List
      loadingList: observable,
      loadingModal: observable,
      productListBySearchKey: observable,
      productListByCategory: observable,
      listFilterItems: observable,
      // End Product List
      // Product Detail Page
      loadingDateRange: observable,
      loadingPriceOptions: observable,
      checkingAva: observable,
      productDetail: observable,
      productDateRange: observable,
      priceOptions: observable,
      avaInfo: observable,
      // End Product Detail Page
      // Travel Planning Page
      productListByTPBooking: observable,
      groupListByBooking: observable,
      // End Travel Planning Page
      getBannersByLanguage: observable,
      getPromotionProductList: action,
      searchProductByTPBooking: action,
      searchProductByString: action,
      getProductDetailById: action,
      getProductDateRange: action,
      getLowestPrice: action,
      getGroupProductByString: action,
    });

    this.rootStore = rootStore;
  }

  getBannersByLanguage = async (language: Language) => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/Product/GetBannersByLanguage/${language}`);
      this.bannerList = res.data;
      console.log("Banner List", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  getPromotionProductList = async (promotionType: ContentType, lang: Language) => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/Product/GetPromotionProductList/${promotionType}?language=${lang}`);
      if (promotionType == ContentType.Promotion) this.promotionList = res.data;
      else if (promotionType == ContentType.Popular) this.popularList = res.data;
      else if (promotionType == ContentType.New) this.newPromotionList = res.data;
      console.log("Product List " + promotionType, res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  searchProductByString = async (searchStr: string, language: Language) => {
    this.loadingList = true;
    try {
      const res = await Axios.post(`/api/Product/SearchProductByString?searchStr=${searchStr}&language=${language}`);
      this.productListBySearchKey = res.data.items;
      this.listFilterItems = res.data.filterItems;
      console.log("Product List Search Key", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingList = false;
    }
  }

  searchProductByTPBooking = async (searchStr: string, language: Language) => {
    this.loadingList = true;
    try {
      const res = await Axios.post(`/api/Product/SearchProductByString?searchStr=${searchStr}&language=${language}`);
      console.log("Product List Search Key (travel planning booking)",res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingList = false;
    }
  }

  searchProductByCategory = async (cat: SearchCategory, value: string | ProductType, lang: Language) => {
    this.loadingList = true;
    try {
      const category = cat.charAt(0).toUpperCase() + cat.slice(1);
      const res = await Axios.post(`/api/Product/SearchProductByCategory?category=${category}&value=${value}&language=${lang}`);
      this.productListByCategory = res.data.items;
      this.listFilterItems = res.data.filterItems
      console.log("Product List Category", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingList = false;
    }
  }

  getGroupProductByString = async (searchTerm: string) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/Product/GetGroupProductByString?searchTerm=${searchTerm}&language=${GetLanguage()}`);
      this.groupListByBooking.push({
        searchKey: searchTerm,
        items: res.data,
      });
      console.log("Product List Category", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  setLoadingList = (load: boolean) => this.loadingList = load;

  // Product Detail Page
  getProductDetailById = async (productId: number, lang: Language, forModal?: boolean) => {
    this.productDetail = null;
    this.productDateRange = [];
    if(forModal) this.loadingModal = true;
    else this.loading = true;
    try {
      const res = await Axios.get(`/api/Product/GetProductDetailById?id=${productId}&language=${lang}`);
      this.productDetail = res.data;
      this.avaInfo = null;
      console.log("Product Detail", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
      this.loadingModal = false;
    }
  }

  getProductDateRange = async (productId: number) => {
    this.loadingDateRange = true;
    try {
      const res = await Axios.get(`/api/Product/GetProductDateRange?id=${productId}`);
      this.productDateRange = res.data.dateRange;
      console.log("Product Date Range", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingDateRange = false;
    }
  }

  getLowestPrice = async (productId: number, date: string) => {
    this.loadingPriceOptions = true;
    try {
      const res = await Axios.get(`/api/Product/GetLowestPrice?id=${productId}&strDate=${date}`);
      this.priceOptions = res.data;
      console.log("Product Price Options", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingPriceOptions = false;
    }
  }

  checkAvailability = async (reqModel: any) => {
    this.checkingAva = true;
    try {
      const res = await Axios.post(`/api/Product/CheckAvailability`, reqModel);
      this.avaInfo = res.data;
      console.log("Availability Info", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.checkingAva = false;
    }
  }
  // End Product Detail Page
}
