import './src/styles/global.css';
import React, { useEffect } from 'react';
import { I18nextProvider, useTranslation } from 'react-i18next';
import './src/localization/i18n';
import { Language } from './src/constants/options';
import { SnackbarProvider } from 'notistack';
import NotificationProvider from './src/components/notification';
import { isWeb } from './src/utilities/platform';

const TranslationProvider = ({ children }) => {
    const { i18n } = useTranslation();
    useEffect(() => {
        if (isWeb && localStorage.LANG) {
            i18n.changeLanguage(localStorage.LANG);
        } else {
            i18n.changeLanguage(Language.ENGLISH);
            localStorage.LANG = Language.ENGLISH
        }

    }, []);
    return (
        <I18nextProvider i18n={i18n}>{children}</I18nextProvider >
    )
}
export const wrapRootElement = ({ element, props }) => {
    return (
        <SnackbarProvider>
            <NotificationProvider>
                <TranslationProvider>
                    {element}
                </TranslationProvider>
            </NotificationProvider>
        </SnackbarProvider>
    )
}