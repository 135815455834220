import { observable, action, makeObservable } from 'mobx';
import UserStore from './user-store';
import PermissionStore from './permission-store';
import TraineeshipStore from './product-store';
import FileStore from './filter-store';
import ProductStore from './product-store';
import FilterStore from './filter-store';
import CartStore from './cart-store';
import BookingStore from './booking-store';

interface WebNotification {
  message: string,
  options?:
  {
    variant: 'success' | 'error' | 'info' | 'warning'
  },
}

export default class RootStore {
  testVal = 'TEST_VALUE';

  destination = 'Select';

  notification: WebNotification = {
    message: "",
    options: {
      variant: "info"
    }
  }

  userStore;

  permissionStore;

  productStore;

  filterStore;

  cartStore;

  bookingStore;


  constructor() {
    makeObservable(this, {
      testVal: observable,
      destination: observable,
      notification: observable,
      notify: action,
    });
    this.userStore = new UserStore(this);
    this.permissionStore = new PermissionStore(this);
    this.productStore = new ProductStore(this);
    this.filterStore = new FilterStore(this);
    this.cartStore = new CartStore(this);
    this.bookingStore = new BookingStore(this);
  }

  notify(msg: string, level?: 'success' | 'error' | 'info' | 'warning') {
    if (level) {
      this.notification = {
        message: msg,
        options: {
          variant: level,
        },
      };
    } else {
      this.notification = {
        message: msg,
      };
    }
  }
}
