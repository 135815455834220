const login = {
  HI: '嗨',
  SIGN_IN: '登录',
  SIGN_IN_NOW: '登录',
  FORGOT_PASSWORD: '忘记密码',
  USERNAME: '用户名',
  PASSWORD: '密码',
  CONFIRM_PASSWORD: '确认密码',
  TWO_PASSWORDS_NOT_MATCH: '密码不匹配',
  INCORRECT_USERNAME_PASSWORD: '用户名或密码错误',
  EMAIL: '邮箱',
  CONFIRM_EMAIL: '确认邮箱',
  TWO_EMAILS_NOT_MATCH: '邮箱不匹配',
  INVALID_EMAIL: '无效邮箱',
  SEND_VERIFICATION_EMAIL: '发送验证邮件',
  VERIFICATION_EMAIL_SENT: '验证邮件已发送',
  RESET_PASSWORD: '重置密码',
  RESET_PASSWORD_FAILED: '重置密码失败',
  VERIFY_URL_LINK: '认证链接',
  SIGN_OUT: '登出',
  SIGN_OUT_CHECK: '确认登出',
  VALIDATING_USER_CREDENTIAL: '认证用户信息中',
  BYE: '再见',
  SIGN_UP_SUCCESSFULLY: '申请成功，请前往您的邮箱激活您的账号',
  AUTHENTICATION_EXPIRED: '用户登录状态过期',
  LOGIN: '登录',
  SIGN_UP: '申请账户',
  CHECKOUT_AS_GUEST: '以游客身份结账',
  DONT_HAVE_ACCOUNT: "还没有TOUR61.com账户",
  CONTINUE_AS_GUEST_AND_CREATE_ACCOUNT_AFTERWARDS: "不用担心！您可以先以游客身份结账付款后免费注册一个新用户",
  ACCOUNT_BENEFITS: '账户优势',
  FAST_EZ_CHECK_OUT: '以更加快捷的方式进行结账',
  COLLECT_REWARD_PTS: '收集您的奖励积分',
  ACCESS_TO_ORDER_HISTORY: '回顾订单记录',
  CONTINUE_AS_GUEST: '以游客身份继续',
  CREATE_NEW_ACCOUNT_OR_LOGIN: '注册/登录',
  CREATE_YOUR_ACCOUNT: '创建您的账户',
  PLS_FILL_IN_ALL_FIELDS: '请确所有空位填写完整',
  EMAIL_ALREADY_EXISTS_PLS_TRY_AGAIN: '邮箱已被使用，请重试',
}

const menu = {
  HOME: '主页',
  TRAVEL_PLANNING: '路线规划',
  ACTIVITIES: '门票',
  DAY_TRIPS: '一日游',
  MULTI_DAY_TOURS: '多日游',
  'MULTI_DAY-TOURS': '多日游',
  LOGIN_REGISTER: '登录/免费注册',
  CART: '购物车',
  MY_ORDER: '我的订单',
  WISHLIST: '收藏夹',
  HELLO: '你好',
  SAIL_TOGETHER: '让我们一起扬帆起航。',
  TRIP_NEVER_FORGET: '您将拥有一次终生难忘的旅行。',
  SEARCH_BY_DEST_ATTRACTIONS_TOURS: '搜景点/目的地',
  USER_PROFILE: '账户信息',
}

const footer = {
  POLICY: "条款",
  COMPANY: "公司",
  CONTACT_US: "联系我们",
  PRIVACY_POLICY: "隐私条款",
  TERMS_CONDITIONS: "条款和条件",
  ABOUT_US: "关于我们",
  HOW_TO_FIND_US: "如何找到我们",
  TERMS_CONDITION: "条款和条件",
}

const homePage = {
  NEW_PROMOTION_LIST_DESCRIPTION: '去过悉尼，那你知道在悉尼可以亲手喂鲨鱼吗？去过墨尔本，那你知道那里有澳洲最大的滑雪场吗？去过凯恩斯，那你知道...这里专门给你藏在深巷里的佳酿',
  ACTIVITY: '多快好省',
  MEANINGFUL_ACTIVITIES: '跟着买手捡便宜',
  MORE: '更多',
  POPULAR_LIST_DESCRIPTION: '澳洲那么大，好玩的地方那么多，漂亮的景色到处是。但是又懒得做攻略，那就跟着其他旅友/高手，看看他们都去哪儿',
  FRESHLY: '深藏不露',
  HAND_PICKED: '跟着达人玩点不一样的',
  MORE_BANG: '高手林立',
  FOR_YOUR_BUCK: '跟着旅友挑好货'
}

const searchBar = {
  WHERE_U_GO: '去哪儿',
  WUT_U_DO: '做些什么',
  SEARCH_FOR: '搜索',
  NO_RESULT_FOUND: '未找到结果',
  TOUR_ACTIVITIES: '旅行与活动',

}

const productListPage = {
  PRODUCT_LIST: '产品列表',
  PRODUCT_ADDED_TO_WISHLIST: '添加到收藏夹',
  FILTER: '筛选',
  POPULAR_DESTINATION: '目标地点',
  CATEGORY: '类别',
  LANGUAGE: '语言',
  RESULTS_FOUND: '条结果',
  SHOW_MORE: '展开',
  SHOW_LESS: '折叠',
  SEARCH_PRODUCTS_IN_THIS_PAGE: '搜索当前页面的产品',
  SEARCH_BY_PRODUCT_NAME: '搜索产品名称',
  SEARCH_BY: '搜索',
  CLEAR: '清空',
}

const productDetailPage = {
  PRODUCT_DETAIL: '产品信息',
  PRODUCT_NOT_RELEASED: '此产品未推出/已经售罄',
  INSTANT_CONFIRM: '立即确认',
  STANDARD_INCLUSIONS: '费用包含',
  AVA: '可选',
  FROM: '',
  FROM_B: '起',
  TRAVEL_DATE: '旅行日期',
  DEPARTURE_DATE: '出发日期',
  CHECK_AVAILABILITY: '立即查询',
  ADD_TO_CART: '加入购物车',
  KEY_FACTS: '产品概要',
  OVERVIEW: '概览',
  YOTPO_REVIEWS: 'Yotpo评测',
  INCLUDED: '包含',
  EXCLUDED: '不包含',
  OPERATING_HOURS: '营业时间',
  OTHER_INFO: '其他信息',
  DESTINATION: '目的地',
  HOW_TO_GET_THERE: '如何前往',
  CHECK_IN_REQUIREMENT: '兑换要求',
  ITINERARY: '行程介绍',
  PRODUCT_HAS_ADDED_TO_YOUR_CART: '产品已加入购物车',
  INVALID_TICKET_QUANTITY_SELECTED: '无效售票数量',
  MIN_QTY: '最低数量',
  MAX_QTY: '最高数量',
  PROCEED_TO_CART: '前往购物车',
  YOUR_ITEM_HAS_BEEN_ADDED: '成功加入购物车',
  NO_TICKET_OPTIONS_AVA: '售票暂无开放',
  WHAT_TO_BRING_WEAR: '建议携带/穿戴',
  UNAVAILABLE: '不可用',
  ONLY: '只剩',
  LEFT: '张票',
  OPERATION_FAILED_PLS_TRY_AGAIN_OR_CONTACT_ADMIN: '操作失败，请稍后重试!',
  DEPARTURE_POINT: '出发地点',
  SELECTED_TRAVEL_DATE: '已选出发时间',
  NO_SELECTED: '还未选择出发时间',
}

const cartPage = {
  YOUR_CART_IS_EMPTY: '空的购物车',
  SHOPPING_CART: "购物车",
  DELETE_SELECTED_ACTIVITY: "删除已选择产品",
  TOTAL_COST: "合计",
  CONTINUE_SHOPPING: "继续购物",
  PROCEED_TO_CHECKOUT: "去结账",
  ORDER_TOTAL: '订单总价',
  REVIEW_ORDER: '浏览订单',
  SECURE_CHECKOUT: '加密付款',
  COMPLETED_ORDER: '完成订单',
  ITEMS_REMOVED_FROM_CART: '个产品已从购物车移除',
  ALL: '全部',
  EXPIRED: '已过期',
}

const checkOutPage = {
  CHECK_OUT: '结账',
  CONTACT_INFO_LEADER_TRAVELLER: '联系方式:主要参与人',
  GENDER: '性别',
  AREA_CODE: '国家/地区',
  NUMBER: '手机号码',
  BOOKING_DETAILS: '订单列表',
  SPECIAL_REQUIREMENT: '特殊要求',
  LEAD_TRAVELLER: '主要参与人',
  TRAVELLER: '参团人',
  FIRST_NAME: '名',
  SURNAME: '姓',
  MALE: '男性',
  FEMALE: '女性',
  PLS_PROVIDE_FULL_DETAILS_OF_EACH_PASSENGER: '请提供每名旅客的详情信息',
  BONUS: '使用优惠券/积分',
  PROMOTION_CODES: '优惠码',
  APPLY: '使用',
  REWARD_POINTS: '奖励积分',
  USE: '使用',
  WORTHING: '可兑换',
  MINIMUM_PAYMENT_POINT: '低于最低分数兑换限制',
  YOU_DONT_HAVE_ENOUGH_POINTS: "您没有足够的分数",
  REWARD_POINT_DISCOUNT: '奖励积分折扣',
  PROMOTION_DISCOUNT: '优惠折扣',
  PROMOTION_CODE_INFO: '优惠信息',
  VALID_CODE: '有效优惠码',
  INVALID_CODE: '无效优惠码',
  PROMOTION_TYPE: '优惠方式',
  PAYMENT: '付款',
  CREDIT_CARD: '信用卡',
  POLI: 'POLi',
  PAY_NOW_PLACE_ORDER: '付款/完成订单',
  SUMMARY: '订单总结',
  READ_TERMS_CONDITION_POLICY: '点击付款即代表您已经明确表示已经阅读并接受享乐旅游网的条款及细则，以及取消条款',
  PROCESSING_ORDER: '处理订单中',
  WOULD_YOU_LIKE_TO_CREATE_AN_ACCOUNT: '同时建立账户？',
  YES: '是',
  NO: '否',
  AGREE_TO_SUBSCRIBE_TOUR61: '订阅TOUR61促销信息',
  DEPARTURE_TIME: '出发时间',
  DEPARTURE_FLIGHT_NO: '出发航班号',
  DEPARTURE_FLIGHT_TYPE: '出发航班类型',
  RETURN_DATE: '回程日期',
  RETURN_TIME: '回程时间',
  RETURN_FLIGHT_NO: '回程航班号',
  RETURN_FLIGHT_TYPE: '回程航班类型',
  DOMESTIC: '国内航班',
  INTERNATIONAL: '国际航班',
  ONE_OR_MORE_FIELDS_IN_CONTACT_INFO_SECTION_ARE_NOT_FILLED_CORRECTLY: '请确保联系方式填写完整及正确',
  ONE_OR_MORE_FIELDS_IN_BOOKING_SECTION_ARE_NOT_FILLED_CORRECTLY: '请确保订单列表填写完整及正确',
  INVALID_EMAIL_FORMAT: '无效邮箱地址',
  WEIGHT: '体重',
  HEIGHT: '身高',
  PLS_SELECT_YOUR_PAYMENT_METHOD: '请选择付款方式！',
  SELECT: '选择',
  BOOKING_FIELDS: '预订详情',
  CANCEL_BONUS: '取消应用优惠码/积分',
  INVALID_PROMOTION_CODE: '无效优惠码',
  PROMOTION_CODE: '优惠码',
  AMOUNT_YOU_EXCHANGED_EXCEEDS_THE_TOTAL_ORDER_PRICE: '您兑换的金额超过订单总价',
}

const confirmationPage = {
  ORDER_CONFIRMATION: '订单确认',
  CONFIRMATION: '订单确认',
  THANK_YOU_FOR_UR_ORDER_FROM_TOUR61: '感谢您的订单',
  YOUR_ORDER_NUM_IS: '您的订单号为',
  YOUR_VOUCHER_WILL_BE_SENT_VIA_EMAIL: '稍后请前往您的电子邮箱查收确认凭证',
  QUANTITY: '数量',
  TOTAL: '总计',
  MANAGE_MY_BOOKINGS: '管理我的订单',
  PAYMENT_CANCELLED: '付款取消',
  PAYMENT_FAILED: '付款失败',
  CHECK_OUT_AGAIN: '再次结账',
  DISCOUNT: '折扣',
  THIS_PAYMENT_WAS: '不好意思！此次付款',
  CANCELLED: '已被取消',
  FAILED: '失败',
  U_CAN_GO_TO_CART_AND_MAKING_PAYMENT_AGAIN: '请返回购物车并尝试重新付款',
  PLEASE_DONT_LEAVE_THE_PAGE: "请不要离开此页面",
  PROCESSING_PAYMENT: '正在处理您的付款',
}

const wishList = {
  ADD_TO_WISHLIST: "添加到收藏夹",
  REMOVE_FROM_WISHLIST: "从收藏夹里移除",
  LOGIN_REQUIRED: '需要登入用户才能使用收藏功能',
  PRODUCT_REMOVED_FROM_WISHLIST: '产品已从收藏夹里移除',
}

const profile = {
  FULL_NAME: '全名',
  PHONE: '电话',
  CHANGE_PASSWORD: "更改密码",
  ACCOUNT: '账户',
  MY_ACCOUNT: '我的账户',
  NICKNAME: '昵称',
  PLS_ENTER_NUM_ONLY_NO_OTHERS_ALLOWED: '此栏只接受数字, 不接受空格或符号。',
  NATIONALITY: '国籍',
  SAVE: '保存',
  BOOKINGS: '订单',
  UPCOMING: '即将出发',
  HISTORY: '过往历史',
  ORDER_NO: '订单号',
  TRAVEL_DATE_PRODUCT: '出发日期/产品',
  ORDER_DATE: '订单日期',
  MESSAGE: '消息',
  VIEW: '查看',
  ORDER_STATUS: '订单状态',
  REQUEST_CANCEL: '申请取消',
  PRODUCT: '产品',
  ENTER_YOUR_DESCRIPTION: '输入详情',
  CANCEL: '取消',
  SEND: '发送',
  NAME: '名称',
  DETAILS: '详情',
  BACK_TO_BOOKINGS: '返回订单列表',
  NO_PRODUCTS_FOUND: '未发现产品',
  STATUS: '消息状态',
  MARK: '标记状态',
  TYPE: '类别',
  REPLIES: '回复',
  LAST_POST: '最后发送时间',
  NO_MESSAGES_FOUND: '无消息',
  YOUR_REWARD_PTS: '你的奖励积分',
  EXPIRED_DATE: '有效日期',
  POINT_TRANSACTIONS: '积分交易记录',
  SHOW: '展示',
  ENTRIES: '条数据',
  TRANSACTION_NO: '交易ID',
  SOURCE: '来源',
  SUBMIT_TIME: '提交时间',
  MAX_500_CHARS: '限500个字符',
  SENT_BY: '发送者',
  ORDER: '订单',
  BACK_TO_MSG: '返回消息列表',
  UPDATE_SUCCESS: '更新成功',
  UPDATE_FAILED: '更新失败，请稍后再试',
  REQUEST_SENT: '请求发送成功',
  REQUEST_FAILED: '请求发送失败，请稍后再试',
  DATE: '日期',
  SEARCH_DATE_TYPE_SOURCE: '搜索日期 (01/01/2013)、类别 (Redeem)、来源(Product purchase)、及订单号(11111)',
  PRODUCT_NO: '产品号',
  SEARCH: '搜索',
  MSG_TYPE: '消息类别',
  INBOX: '收件箱',
  UNREAD: '未读',
  READED: '已读',
  NOT_MARKED: '未标记',
  MARKED: '已标记',
  YOUR_CURRENT_PASSWORD: '你的当前密码',
  NEW_PASSWORD: '新密码',
  UPDATE_NOW: '更新',
  PASSWORD_UPDATED: '密码已更新',
  INCORRECT_CURRENT_PASSWORD: '当前密码错误，请重试',
  PAID: '已付款',
  UNPAID: '未付款',
  REFUNDED: '已退款',
  FAILED: '付款失败',
  OTHER_COUNTRIES_DISTRICT: '其他国家/地区',
  UNKNOWN: '未知',
  MSG_STATUS_TO: '消息已设置为',
  YOU: '你',
  CONTACT_DETAIL: '联络信息',
  ENQUIRY: '咨询产品',
}

const verification = {
  EMAIL_VERIFICATION: '邮箱验证',
  VALIDATING_YOUR_EMAIL: '正在验证您的邮箱',
  THANK_YOU_YOUR_EMAIL_VALIDATED: '恭喜！您的邮箱已验证成功！',
  NOW_U_CAN_LOGIN_TO_YOUR_ACCOUNT: '你现在可以成功登入您的账户',
  FAILED_TO_VALIDATE_EMAIL_PLS_TRY_AGAIN: '邮箱验证失败，请刷新页面重试！',
  GO_HOME: '返回主页',
  TRY_AGAIN: '重试',
  A_TEMP_PW_HAS_SENT_TO_YOUR_EMAIL: '您的临时登入密码已发送到您的邮箱',
  PLS_PICK_ONE_OF_THE_OPTION_BELOW_AND_FILL_IN_THE_FIELDS: '请在以下选项里选择一项来填写您的账户有关信息',
  OPTION_1_ENTER_FIRST_NAME_SURNAME: '选项1: 填写您的账户姓名',
  OPTION_2_ENTER_YOUR_MOBILE: '选项2: 填写您的账户电话号码',
  SEND_VERIFICATION_EMAIL: '发送验证',
  RESET_PASSWORD_VERIFICATION_EMAIL_SENT: '重置密码验证邮件已发送',
  EMAIL_SENT_FAILED_PLS_CHECK_YOUR_INPUT_TRY_AGAIN: '验证邮件发送失败，请再次检查您的填写信息并重试',
}

const travelPlanningPage = {
  PRODUCE_YOUR_UNIQUE_PLAN_USING_OUR_TRAVEL_PLANNING: '使用我们的旅行计划系统来创建您自己的独特计划',
  PLAN_AUTOMATION: '计划自动化',
  FULL_CUSTOMISED_TRAVEL_PLAN: '高度自定义的旅行计划',
  CLEAR_ROUTE_PLANNER: '提供准确的路线规划',
  BACK_TO_PREVIOUS_TRIP_PLANNING: '返回之前的旅行计划',
  WHAT_IS_YOUR_TRAVELLING_PURPOSES: '您旅行的目的是什么',
  WHICH_CTS_YOU_ARE_LOOKING_TO_TRAVEL: '选择一个城市来开启您的旅行',
  HOW_MANY_TRIP_DAYS_YOUR_PLAN_FOR_MAX_14_DAYS: '您计划的旅行天数（最多 14 天）',
  WHATS_YOUR_TRAVEL_DATE: '您的旅行日期',
  WHAT_IS_THE_MINUMUM_ATTRACTIONS_THAT_YOU_WANT_TO_VISIT: '请提供您要参观最少景点数量是多少',
  WHAT_AREAS_YOU_ARE_MOSTLY_INTERESTED_ON: '在旅行中您最感兴趣的领域是什么（可多选）',
  TIP_SELECT_THE_INTERESTED_AREAS_AND_CLICK_ADD_BUTTON_ADDED_TO_THE_BELOW_AREA: '提示:选择感兴趣的区域并点击添加按钮添加到下方的确认区域',
  DAYS: '天',
  ATTRACTIONS: '个景点',
  NO_INTERESTED_AREA_SELECTED: '未选择感兴趣的区域',
  PLAN_THIS_TRIP: '计划这次旅行',
  BACK_TO_YOUR_PLAN: '返回上一步',
  STEP_1_ADD_RECOMMENED_PLACES_TO_YOUR_TRIP: '将此列表中的推荐地点添加到右侧的当天旅游计划中',
  STEP_2_EDIT_YOUR_TRIP: '编辑您这一天的旅行计划',
  STEP_1: '第1步',
  STEP_2: '第2步',
  ADD_A_NEW_TRIP_DAY: '添加新的旅行日',
  DELETE_CURRENT_DAY: '删除当前旅行日',
  REVERT_TRIP: '还原至初始计划',
  ARE_YOU_SURE_IN_DELETING_THIS_TRIP_DAY: '您确定要删除这一天的旅行安排吗？ 请注意此操作将清除此计划的预订历史',
  ARE_YOUR_SURE_TO_REVERT_YOUR_PLANNING_TO_DEFAULT: '您确定要将当前计划恢复为初始计划吗？ 请注意此操作将清除此计划的预订历史',
  REVERT_SUCCESS: '还原成功',
  DELETE_NOW: '立即删除',
  REVERT_NOW: '立即恢复',
  NEW_EMPTY_TRIP_DAY: '此旅行日当前无任何安排',
  BOOKED: '已预订',
  SET_UR_START_POINT: '设置您这一天旅行的起点',
  ROUTE_PLANNER: '路线规划师',
  WANT_TO_BOOK: '想预订',
  GENERATING_RECOMMENED_ROUTE: '生成推荐路线',
  TRAVEL_BY: '旅行',
  DRIVING: '驾车',
  ROUTE_SUMMARY: '路线总结',
  YOU_NEED_ADD_AT_LEAST_TWO_LOCATIONS_TO_VIEW_THE_ROUTE_FOR_CURRENT_TRIP_DAY: '您需要至少添加两个景点才能看到当前旅行日的路线',
  ATTRACTION_ADDED: '已添加景点',
  ATTRACTION_REMOVED: "景点已移除",
  NEW_TRIP_DAY_ADDED: '添加了新的旅行日',
  TRIP_DAY_DELETE: '删除旅行日',
  DAY_A: '第',
  DAY_B: '天',
  SAME_PLACES_ALLOWED: '允许为不同的旅行日选择相同的景点',
  START_POINT: '起点',
  TRIP_OVERVIEW: '行程概览',
  TALK_ABOUT_YOUR_PLAN: "谈谈您的计划",
  PLAN_YOUR_TRIP_DAYS: '计划您的旅行',
  MAKE_BOOKING: '开始预订',
  START: '开始',
  TOTAL_DISTANCE: '总距离',
  DURATION: "行程时间",
  WAYPOINT: '航点',
  END: '结束',
  YOUR_TRIP_SUMMARY: '您的旅行计划总结',
  LOADING: '加载中',
  STEP_1_TRAVEL_DATE: '第 1 步 - 选择旅行日期',
  STEP_2_PRICE: '第 2 步 - 选择价格选项',
  SELECTED_TRAVEL_DATE: '选定的旅行日期',
  NO_SELECTED: '未选择旅行日期',
  BOOK: '预订',
  GENERATING_SUMMARY: '总结生成中',
  GENERATING_YOUR_PERSONAL_TRAVEL_PLAN: '生成您的个人旅行计划',
  PLEASE_BE_PATIENT: '请耐心等待',
  NOTE_THE_NUMBER_OF_TRIP_DAYS_COULD_AFFECT_GENERATING_SPEED: '注意：当您计划的旅行天数超过7天后将可能会影响计划生成速度',
  STEP_3_SELECT_A_PRODUCT_FROM_THE_SCHEDULED_PLACES_AND_ADD_TO_CART: '第 2 步：根据您的日程安排推荐的产品如下所示。请随意挑选您感兴趣的任何产品并将它们添加到您的购物车',
  NO_AVAILABLE_PRICE_OPTIONS_PLEASE_RETRY_WITH_ANOTHER_DATE: '当前日期的票暂不支持订购，请通过选择不同的旅行日期来重新获取其他价格选项',
  FAMILY_TRIP: '家庭旅行',
  BUSINESS_TRIP: '商务旅行',
  HONEY_MOON: '蜜月旅行',
  ADELAIDE: '阿德莱德',
  BRISBANE: '布里斯班',
  CANBERRA: '堪培拉',
  CAIRNS: '凯恩斯',
  DARWIN: '达尔文',
  MELBOURNE: '墨尔本',
  PERTH: '珀斯',
  SYDNEY: '悉尼',
  TASTE_LOCAL_FOOD: '当地美食',
  AMUSEMENT_PARK: '游乐园',
  NATURAL_ATTRACTIONS: '自然景点',
  HISTORY_ATTRACTIONS: '历史名胜',
  ALL_RECOMMENED_ATTRACTIONS_HAS_BEEN_SELECTED: '所有推荐景点都已完成安排',
  TIP_U_CAN_TURN_ON_THE_SAME_PLACES_ALLOWED_SWITCH_ABOVE_TO_ALLOW_YOURSELF_TO_SELECT_SAME_ATTRACTIONS_FOR_DIFFERENT_TRAVEL_DAYS: '提示：您可尝试打开上方开关以开启景点多选功能。通过开启此功能将允许相同景点可被安排进不同旅行日。',
  SET_AS_START_POINT: '设为行程起点',
  START_POINT_IS_SET_TO: '当天行程的起点已设置为',
  BOOKING: '预定',
  NO_PRODUCT_SELECTED: '未选择任何产品',
  EACH_DAY_CAN_CONTAINED_MAX_8_PLACES: '每个行程日最多只能规划8个景点',
  BACK_TO_PLANNING: '返回至行程安排页面',
  EXPORT_AS_PDF: '通过PDF导出',
}

export default {
  translation: {
    ...login,
    ...menu,
    ...footer,
    ...homePage,
    ...searchBar,
    ...productListPage,
    ...productDetailPage,
    ...checkOutPage,
    ...confirmationPage,
    ...cartPage,
    ...wishList,
    ...profile,
    ...verification,
    ...travelPlanningPage,
  }
};



