/* eslint-disable no-unused-vars */
import {
  action, computed, observable, runInAction, toJS, makeObservable,
} from 'mobx';
import { Language } from '../constants/options';
import { IsAuthed } from '../utilities/general';
import { Axios } from '../utilities/network';
import RootStore from './root-store';

export default class CartStore {

  loading: boolean = false;

  loadingCartList: boolean = false;

  cartItemNum: number = 0;

  cartList: any = { total: 0, productList: [] };

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      loading: observable,
      loadingCartList: observable,
      cartItemNum: observable,
      cartList: observable,
      getCartItemList: action,
      addCartItem: action,
      deleteCartItem: action,
    });

    this.rootStore = rootStore;
  }

  getCartItemNumber = async () => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/Cart/GetCartItemNumber`);
      this.cartItemNum = res.data;
      console.log("Cart Number", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  getCartItemList = async (lang: Language) => {
    // this.loading = true;
    this.loadingCartList = true;
    try {
      const res = await Axios.get(`/api/Cart/GetCartItemList/${lang}`);
      this.cartList = res.data;
      this.cartItemNum = res.data.productList ? res.data.productList.length : 0;
      console.log("Cart List", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      // this.loading = false;
      this.loadingCartList = false;
    }
  }

  addCartItem = async (reqModel: any) => {
    let isGuest = true;
    if (IsAuthed()) isGuest = false;
    this.loading = true;
    try {
      const res = await Axios.post(`/api/Cart/AddCartItem?isGuest=${isGuest}`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  deleteCartItem = async (itemList: any) => {
    this.loading = true;
    try {
      const res = await Axios.put(`/api/Cart/DeleteCartItem`, itemList);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }



}
