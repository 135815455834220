const login = {
  HI: 'Hi',
  SIGN_IN: 'Sign In',
  SIGN_IN_NOW: 'Sign In Now',
  FORGOT_PASSWORD: 'Forgot Password',
  USERNAME: 'Username',
  PASSWORD: 'Password',
  CONFIRM_PASSWORD: 'Confirm Your Password',
  TWO_PASSWORDS_NOT_MATCH: 'Passwords do not match',
  INCORRECT_USERNAME_PASSWORD: 'Incorrect Username/Password',
  EMAIL: 'Email',
  CONFIRM_EMAIL: 'Confirm Email',
  TWO_EMAILS_NOT_MATCH: 'Emails Do Not Match',
  INVALID_EMAIL: 'Invalid Email',
  SEND_VERIFICATION_EMAIL: 'Send Verification Email',
  VERIFICATION_EMAIL_SENT: 'Verification Email Sent',
  RESET_PASSWORD: 'Reset Password',
  RESET_PASSWORD_FAILED: 'Reset Password Failed',
  VERIFY_URL_LINK: 'Verify URL Link',
  SIGN_OUT: 'Sign Out',
  SIGN_OUT_CHECK: 'Are you wish to you sign out now',
  VALIDATING_USER_CREDENTIAL: 'Validating User Credentials',
  BYE: 'Bye',
  SIGN_UP_SUCCESSFULLY: 'Sign up successful, please proceed to your email and activate your account',
  AUTHENTICATION_EXPIRED: 'Authentication expired',
  LOGIN: 'Login',
  SIGN_UP: 'Sign Up',
  CHECKOUT_AS_GUEST: 'Check out as guest',
  DONT_HAVE_ACCOUNT: "Don't have a TOUR61.com account",
  CONTINUE_AS_GUEST_AND_CREATE_ACCOUNT_AFTERWARDS: "Don't worry! You can complete your checkout as a guest and choose to create an account afterwards",
  ACCOUNT_BENEFITS: 'Account Benefits',
  FAST_EZ_CHECK_OUT: 'Fast and easy checkout',
  COLLECT_REWARD_PTS: 'Collect reward points',
  ACCESS_TO_ORDER_HISTORY: 'Access to order history',
  CONTINUE_AS_GUEST: 'Continue As Guest',
  CREATE_NEW_ACCOUNT_OR_LOGIN: 'Create new account or login',
  CREATE_YOUR_ACCOUNT: 'Create Your Account',
  PLS_FILL_IN_ALL_FIELDS: 'Please fill in all fields',
  EMAIL_ALREADY_EXISTS_PLS_TRY_AGAIN: 'Email already exists, please try again',
}

const menu = {
  HOME: 'Home',
  TRAVEL_PLANNING: 'Travel Planning',
  ACTIVITIES: 'Activities',
  DAY_TRIPS: 'Day Trips',
  MULTI_DAY_TOURS: 'Multi-Day Tours',
  'MULTI_DAY-TOURS': 'Multi-Day Tours',
  LOGIN_REGISTER: 'Login/Register',
  CART: 'Cart',
  MY_ORDER: 'My Order',
  WISHLIST: 'Wishlist',
  HELLO: 'HELLO',
  SAIL_TOGETHER: 'Let us sail together.',
  TRIP_NEVER_FORGET: 'You will have a trip that you will never forget.',
  SEARCH_BY_DEST_ATTRACTIONS_TOURS: 'Search by destinations, attractions or tours',
  USER_PROFILE: 'User Profile',
}

const footer = {
  POLICY: "Policy",
  COMPANY: "Company",
  CONTACT_US: "Contact Us",
  PRIVACY_POLICY: "Privacy Policy",
  TERMS_CONDITIONS: "Terms & Conditions",
  ABOUT_US: "About Us",
  HOW_TO_FIND_US: "How to Find Us",
  TERMS_CONDITION: "Terms Condition",
}

const homePage = {
  NEW_PROMOTION_LIST_DESCRIPTION: 'We are delighted to launch a series of exciting new tours which include even more incredible experiences',
  ACTIVITY: 'Activity',
  MEANINGFUL_ACTIVITIES: 'Meaningful Activties',
  MORE: 'More',
  POPULAR_LIST_DESCRIPTION: 'These popular tours will have you discovering new cultures with new firends',
  FRESHLY: 'Freshly',
  HAND_PICKED: 'Hand Picked',
  MORE_BANG: 'More Bang',
  FOR_YOUR_BUCK: 'For Your Buck'
}

const searchBar = {
  WHERE_U_GO: 'Where You Go',
  WUT_U_DO: 'What You Do',
  SEARCH_FOR: 'Search for',
  NO_RESULT_FOUND: 'No Result Found',
  TOUR_ACTIVITIES: 'Tour & Activities',

}

const productListPage = {
  PRODUCT_LIST: 'Product List',
  PRODUCT_ADDED_TO_WISHLIST: 'Product added to wishlist',
  FILTER: 'Filter',
  POPULAR_DESTINATION: 'Popular Destination',
  CATEGORY: 'Category',
  LANGUAGE: 'Language',
  RESULTS_FOUND: 'Result(s) Found',
  SHOW_MORE: 'Show More',
  SHOW_LESS: 'Show Less',
  SEARCH_PRODUCTS_IN_THIS_PAGE: 'Search Products in this Page',
  SEARCH_BY_PRODUCT_NAME: 'Search by Product Name', 
  SEARCH_BY: 'Search By',
  CLEAR: 'Clear',
}

const productDetailPage = {
  PRODUCT_DETAIL: 'Product Detail',
  PRODUCT_NOT_RELEASED: 'This product is sold out or not released yet',
  INSTANT_CONFIRM: 'Instant Confirm',
  STANDARD_INCLUSIONS: 'Standard Inclusions',
  AVA: 'Available',
  FROM: 'From',
  FROM_B: '',
  TRAVEL_DATE: 'Travel Date',
  DEPARTURE_DATE: 'Departure Date',
  CHECK_AVAILABILITY: 'Check Availability',
  ADD_TO_CART: 'Add To Cart',
  KEY_FACTS: 'Key Facts',
  OVERVIEW: 'Overview',
  YOTPO_REVIEWS: 'Yotpo Reviews',
  INCLUDED: 'Included',
  EXCLUDED: 'Excluded',
  OPERATING_HOURS: 'Operating Hours',
  OTHER_INFO: 'Other Info',
  DESTINATION: 'Destination',
  HOW_TO_GET_THERE: 'How To Get There',
  CHECK_IN_REQUIREMENT: 'Check In Requirement',
  ITINERARY: 'Itinerary',
  PRODUCT_HAS_ADDED_TO_YOUR_CART: 'Product has added to your cart',
  INVALID_TICKET_QUANTITY_SELECTED: 'Invalid ticket quantity selected',
  MIN_QTY: 'Minimum Quantity',
  MAX_QTY: 'Maximum Quantity',
  PROCEED_TO_CART: 'Proceed To Cart',
  YOUR_ITEM_HAS_BEEN_ADDED: 'Your item has been added',
  NO_TICKET_OPTIONS_AVA: 'No ticket options available',
  WHAT_TO_BRING_WEAR: 'What To Bring And Wear',
  UNAVAILABLE: 'Unavailable',
  ONLY: 'Only',
  LEFT: 'Ticket Left',
  OPERATION_FAILED_PLS_TRY_AGAIN_OR_CONTACT_ADMIN: 'Operation failed, please try again later!',
  DEPARTURE_POINT: 'Departure Point',
  SELECTED_TRAVEL_DATE: 'Selected Travel Date',
  NO_SELECTED: 'No Travel Date Selected',
}

const cartPage = {
  YOUR_CART_IS_EMPTY: 'Your Cart is Empty',
  SHOPPING_CART: "Shopping Cart",
  DELETE_SELECTED_ACTIVITY: "Delete Selected Activity",
  TOTAL_COST: "Total Cost",
  CONTINUE_SHOPPING: "Continue Shopping",
  PROCEED_TO_CHECKOUT: "Proceed to Checkout",
  ORDER_TOTAL: 'Order Total',
  REVIEW_ORDER: 'Review Order',
  SECURE_CHECKOUT: 'Secure Checkout',
  COMPLETED_ORDER: 'Completed Order',
  ITEMS_REMOVED_FROM_CART: 'Item(s) is removed from the cart',
  ALL: 'All',
  EXPIRED: 'EXPIRED',
}

const checkOutPage = {
  CHECK_OUT: 'Check Out',
  CONTACT_INFO_LEADER_TRAVELLER: 'Contact Info: Lead Traveller',
  GENDER: 'Gender',
  AREA_CODE: 'Area',
  NUMBER: 'Number',
  BOOKING_DETAILS: 'Booking Details',
  SPECIAL_REQUIREMENT: 'Special Requirement',
  LEAD_TRAVELLER: 'Lead Traveller',
  TRAVELLER: 'Traveller',
  FIRST_NAME: 'First Name',
  SURNAME: 'Surname',
  MALE: 'Male',
  FEMALE: 'Female',
  PLS_PROVIDE_FULL_DETAILS_OF_EACH_PASSENGER: 'Please provide full details of each passenger',
  BONUS: 'Bonus',
  PROMOTION_CODES: 'Promotion Codes',
  APPLY: 'Apply',
  REWARD_POINTS: 'Reward Points',
  USE: 'Use',
  WORTHING: 'Worthing',
  MINIMUM_PAYMENT_POINT: 'Minimum exchange point',
  YOU_DONT_HAVE_ENOUGH_POINTS: "You don't have enough points",
  REWARD_POINT_DISCOUNT: 'Reward Points Discount',
  PROMOTION_DISCOUNT: 'Promotion Code Discount',
  PROMOTION_CODE_INFO: 'Promotion Code Info',
  VALID_CODE: 'Valid Code',
  INVALID_CODE: 'Invalid Code',
  PROMOTION_TYPE: 'Promotion Type',
  PAYMENT: 'Payment',
  CREDIT_CARD: 'Credit Card',
  POLI: 'POLi',
  PAY_NOW_PLACE_ORDER: 'Pay Now/Place Order',
  SUMMARY: 'Summary',
  READ_TERMS_CONDITION_POLICY: 'By clicking Pay Now, you agree that you have read and understood our Terms & Conditions and Cancellation Policy',
  PROCESSING_ORDER: 'Processing Order',
  WOULD_YOU_LIKE_TO_CREATE_AN_ACCOUNT: 'Would you like to create an account?',
  YES: 'Yes',
  NO: 'No',
  AGREE_TO_SUBSCRIBE_TOUR61: 'Agree to subscribe to TOUR61',
  DEPARTURE_TIME: 'Departure Time',
  DEPARTURE_FLIGHT_NO: 'Departure Flight No',
  DEPARTURE_FLIGHT_TYPE: 'Departure Flight Type',
  RETURN_DATE: 'Return Date',
  RETURN_TIME: 'Return Time',
  RETURN_FLIGHT_NO: 'Return Flight No',
  RETURN_FLIGHT_TYPE: 'Return Flight Type',
  DOMESTIC: 'Domestic',
  INTERNATIONAL: 'International',
  ONE_OR_MORE_FIELDS_IN_CONTACT_INFO_SECTION_ARE_NOT_FILLED_CORRECTLY: 'One or more fields in CONTACT INFO section are not fill in or filled in correctly',
  ONE_OR_MORE_FIELDS_IN_BOOKING_SECTION_ARE_NOT_FILLED_CORRECTLY: 'One or more fields in BOOKING DETAILS section are not fill in or filled in correctly',
  INVALID_EMAIL_FORMAT: 'Invalid email format',
  WEIGHT: 'Weight',
  HEIGHT: 'Height',
  PLS_SELECT_YOUR_PAYMENT_METHOD: 'Please select your payment method first!',
  SELECT: 'Select',
  BOOKING_FIELDS: 'Booking Fields',
  CANCEL_BONUS: 'Cancel Bonus',
  INVALID_PROMOTION_CODE: 'Invalid Promotion Code',
  PROMOTION_CODE: 'Promotion Code',
  AMOUNT_YOU_EXCHANGED_EXCEEDS_THE_TOTAL_ORDER_PRICE: 'The amount you exchanged exceeds the total order price',
}

const confirmationPage = {
  ORDER_CONFIRMATION: 'Order Confirmation',
  CONFIRMATION: 'Confirmation',
  THANK_YOU_FOR_UR_ORDER_FROM_TOUR61: 'Thank you for your order from TOUR61',
  YOUR_ORDER_NUM_IS: 'Your order number is',
  YOUR_VOUCHER_WILL_BE_SENT_VIA_EMAIL: 'Your voucher will be sent via email',
  QUANTITY: 'Quantity',
  TOTAL: 'Total',
  MANAGE_MY_BOOKINGS: 'Manage My Bookings',
  PAYMENT_CANCELLED: 'Payment Cancelled',
  PAYMENT_FAILED: 'Payment Failed',
  CHECK_OUT_AGAIN: 'Check Out Again',
  DISCOUNT: 'Discount',
  THIS_PAYMENT_WAS: 'This payment was',
  CANCELLED: 'cancelled',
  FAILED: 'failed',
  U_CAN_GO_TO_CART_AND_MAKING_PAYMENT_AGAIN: 'You can go to your cart and try making payment again',
  PLEASE_DONT_LEAVE_THE_PAGE: "Please don't leave the page",
  PROCESSING_PAYMENT: 'Processing your payment',
}

const wishList = {
  ADD_TO_WISHLIST: "Add to Wishlist",
  REMOVE_FROM_WISHLIST: "Remove from Wishlist",
  LOGIN_REQUIRED: 'Login Required',
  PRODUCT_REMOVED_FROM_WISHLIST: 'Product successfully removed from the wishlist',
}

const profile = {
  FULL_NAME: 'Full Name',
  PHONE: 'Phone',
  CHANGE_PASSWORD: "Change Password",
  ACCOUNT: 'Account',
  MY_ACCOUNT: 'My Account',
  NICKNAME: 'Nickname',
  PLS_ENTER_NUM_ONLY_NO_OTHERS_ALLOWED: 'Please enter number only. No space or symbol is accepted.',
  NATIONALITY: 'Nationality',
  SAVE: 'SAVE',
  BOOKINGS: 'Bookings',
  UPCOMING: 'Upcoming',
  HISTORY: 'History',
  ORDER_NO: 'Order No',
  TRAVEL_DATE_PRODUCT: 'Travel Date/Product',
  ORDER_DATE: 'Order Date',
  MESSAGE: 'Message',
  VIEW: 'View',
  ORDER_STATUS: 'Order Status',
  REQUEST_CANCEL: 'Request Cancel',
  PRODUCT: 'Product',
  ENTER_YOUR_DESCRIPTION: 'Enter Your Description',
  CANCEL: 'Cancel',
  SEND: 'Send',
  NAME: 'Name',
  DETAILS: 'Details',
  BACK_TO_BOOKINGS: 'Back to Bookings',
  NO_PRODUCTS_FOUND: 'No Products Found',
  STATUS: 'Status',
  MARK: 'Mark',
  TYPE: 'Type',
  REPLIES: 'Replies',
  LAST_POST: 'Last Post',
  NO_MESSAGES_FOUND: 'No Messages Found',
  YOUR_REWARD_PTS: 'Your Reward Points',
  EXPIRED_DATE: 'Expired Date',
  POINT_TRANSACTIONS: 'Point Transactions',
  SHOW: 'Show',
  ENTRIES: 'Entries',
  TRANSACTION_NO: 'Transaction No',
  SOURCE: 'Source',
  SUBMIT_TIME: 'Submit Time',
  MAX_500_CHARS: 'Maximum of 500 characters',
  SENT_BY: 'Sent by',
  ORDER: 'Order',
  BACK_TO_MSG: 'Back to Message List',
  UPDATE_SUCCESS: 'Update success',
  UPDATE_FAILED: 'Update failed, please try again later',
  REQUEST_SENT: 'Request sent',
  REQUEST_FAILED: 'Request failed, please try again later',
  DATE: 'Date',
  SEARCH_DATE_TYPE_SOURCE: 'Search by date (01/01/2013), type (Redeem), source (Product purchase) & order no (11111)',
  PRODUCT_NO: 'Product No',
  SEARCH: 'Search',
  MSG_TYPE: 'Message Type',
  INBOX: 'Inbox',
  UNREAD: 'Unread',
  READED: 'Readed',
  NOT_MARKED: 'Not Marked',
  MARKED: 'Marked',
  YOUR_CURRENT_PASSWORD: 'Your Current Password',
  NEW_PASSWORD: 'New Password',
  UPDATE_NOW: 'Update Now',
  PASSWORD_UPDATED: 'Password updated',
  INCORRECT_CURRENT_PASSWORD: 'Incorrect current password',
  PAID: 'Paid',
  UNPAID: 'Unpaid',
  REFUNDED: 'Refunded',
  FAILED: 'Failed',
  OTHER_COUNTRIES_DISTRICT: 'Other Countries/Districts',
  UNKNOWN: 'Unknown',
  MSG_STATUS_TO: 'Message status changed to ',
  YOU: 'You',
  CONTACT_DETAIL: 'Contact Detail',
  ENQUIRY: 'Product Enquiry',
}

const verification = {
  EMAIL_VERIFICATION: 'Email Verification',
  VALIDATING_YOUR_EMAIL: 'Validating your email',
  THANK_YOU_YOUR_EMAIL_VALIDATED: 'Thank you! Your email is validated',
  NOW_U_CAN_LOGIN_TO_YOUR_ACCOUNT: 'Now you can login to your account',
  FAILED_TO_VALIDATE_EMAIL_PLS_TRY_AGAIN: 'Failed to validate email! Please refresh the page to try again',
  GO_HOME: 'Go back to Home Page',
  TRY_AGAIN: 'Try Again',
  A_TEMP_PW_HAS_SENT_TO_YOUR_EMAIL: 'A temporary password has sent to your email',
  PLS_PICK_ONE_OF_THE_OPTION_BELOW_AND_FILL_IN_THE_FIELDS: 'Please pick one of the options below and fill in the relevant fields to request password reset',
  OPTION_1_ENTER_FIRST_NAME_SURNAME: 'Option 1: Enter your first name and last name',
  OPTION_2_ENTER_YOUR_MOBILE: 'Option 2: Enter your mobile number',
  SEND_VERIFICATION_EMAIL: 'Send Verification Email',
  RESET_PASSWORD_VERIFICATION_EMAIL_SENT: 'Reset password verification email sent',
  EMAIL_SENT_FAILED_PLS_CHECK_YOUR_INPUT_TRY_AGAIN: 'Email sent failed, please check your input and try again',
}

const travelPlanningPage = {
  PRODUCE_YOUR_UNIQUE_PLAN_USING_OUR_TRAVEL_PLANNING: 'Use our travel planning system to create your own unique plan',
  PLAN_AUTOMATION: 'Plan automation',
  FULL_CUSTOMISED_TRAVEL_PLAN: 'Fully customised travel planning',
  CLEAR_ROUTE_PLANNER: 'Provides an accurate route planner',
  BACK_TO_PREVIOUS_TRIP_PLANNING: 'Back to Previous Trip Planning',
  WHAT_IS_YOUR_TRAVELLING_PURPOSES: 'What is the purpose of your trip',
  WHICH_CTS_YOU_ARE_LOOKING_TO_TRAVEL: 'Select a city to begin your journey',
  HOW_MANY_TRIP_DAYS_YOUR_PLAN_FOR_MAX_14_DAYS: 'How many days you plan to travel (up to 14 days)',
  WHATS_YOUR_TRAVEL_DATE: 'What is your travel date',
  WHAT_IS_THE_MINUMUM_ATTRACTIONS_THAT_YOU_WANT_TO_VISIT: 'What is the minimum number of attractions that you want to visit',
  WHAT_AREAS_YOU_ARE_MOSTLY_INTERESTED_ON: 'What is the areas you are most interested in (can be more than one)',
  TIP_SELECT_THE_INTERESTED_AREAS_AND_CLICK_ADD_BUTTON_ADDED_TO_THE_BELOW_AREA: 'Tip: Select the area of interest and click the Add button to add to the confirmation area below',
  DAYS: 'Day(s)',
  ATTRACTIONS: 'Attractions',
  NO_INTERESTED_AREA_SELECTED: 'No interested area(s) selected',
  PLAN_THIS_TRIP: 'Plan this Trip',
  BACK_TO_YOUR_PLAN: 'Go Back to Talk About Plan',
  STEP_1_ADD_RECOMMENED_PLACES_TO_YOUR_TRIP: 'Add recommended places from this list to the day plan on the right',
  STEP_2_EDIT_YOUR_TRIP: 'Edit your plan for this travel day',
  STEP_1: 'Step 1',
  STEP_2: 'Step 2',
  ADD_A_NEW_TRIP_DAY: 'Add an new travel day',
  DELETE_CURRENT_DAY: 'Delete current travel day',
  REVERT_TRIP: 'Revert this trip plan',
  ARE_YOU_SURE_IN_DELETING_THIS_TRIP_DAY: 'Are you sure you want to delete this travel day? Please note this action will CLEAR THE BOOKING HISTORY for this plan',
  ARE_YOUR_SURE_TO_REVERT_YOUR_PLANNING_TO_DEFAULT: 'Are you sure to revert the current plan to default? Please note this action will CLEAR THE BOOKING HISTORY for this plan',
  REVERT_SUCCESS: 'Revert success',
  DELETE_NOW: 'Delete Now',
  REVERT_NOW: 'Revert Now',
  NEW_EMPTY_TRIP_DAY: 'Empty Travel Day',
  BOOKED: 'Booked',
  SET_UR_START_POINT: 'Set your starting point for this travel day',
  ROUTE_PLANNER: 'Route Planner',
  WANT_TO_BOOK: 'Want to Book',
  GENERATING_RECOMMENED_ROUTE: 'Generating Recommened Route',
  TRAVEL_BY: 'Travel by',
  DRIVING: 'Driving',
  ROUTE_SUMMARY: 'Route Summary',
  YOU_NEED_ADD_AT_LEAST_TWO_LOCATIONS_TO_VIEW_THE_ROUTE_FOR_CURRENT_TRIP_DAY: 'You need to add at least two attractions to see the route for the current travel day',
  ATTRACTION_ADDED: 'Attraction added',
  ATTRACTION_REMOVED: 'Attraction removed',
  NEW_TRIP_DAY_ADDED: 'New travel day added',
  TRIP_DAY_DELETE: 'Travel day removed',
  DAY_A: 'Day ',
  DAY_B: '',
  SAME_PLACES_ALLOWED: 'Allows to select the same attraction for different travel days',
  START_POINT: 'Start Point',
  TRIP_OVERVIEW: 'Trip Overview',
  TALK_ABOUT_YOUR_PLAN: 'Talk About Your Plan',
  PLAN_YOUR_TRIP_DAYS: 'Plan You Trip',
  MAKE_BOOKING: 'Make Booking',
  START: 'Start',
  TOTAL_DISTANCE: 'Total Distance',
  DURATION: 'Duration',
  WAYPOINT: 'Waypoint',
  END: 'End',
  YOUR_TRIP_SUMMARY: 'Your Trip Plan Summary',
  LOADING: 'Loading',
  STEP_1_TRAVEL_DATE: 'Step 1 - Select Travel Date',
  STEP_2_PRICE: 'Step 2 - Pick Price Options',
  SELECTED_TRAVEL_DATE: 'Selected Travel Date',
  NO_SELECTED: 'No Travel Date Selected',
  BOOK: 'Book', 
  GENERATING_SUMMARY: 'Generating Summary',
  GENERATING_YOUR_PERSONAL_TRAVEL_PLAN: 'Generating your personal travel plan',
  PLEASE_BE_PATIENT: 'Please be patient',
  NOTE_THE_NUMBER_OF_TRIP_DAYS_COULD_AFFECT_GENERATING_SPEED: 'Note: When the number of travel days you plan exceeds 7 days, it may affect the speed of plan generation',
  STEP_3_SELECT_A_PRODUCT_FROM_THE_SCHEDULED_PLACES_AND_ADD_TO_CART: 'Step 2: Recommended products based on your schedule are shown below. Feel free to pick any products you are interested in and add them to your shopping cart',
  NO_AVAILABLE_PRICE_OPTIONS_PLEASE_RETRY_WITH_ANOTHER_DATE: 'Not available for booking, please select a different travel day and try again',
  FAMILY_TRIP: 'Family Trip',
  BUSINESS_TRIP: 'Business Trip',
  HONEY_MOON: 'Honey Moon',
  ADELAIDE: 'Adelaide',
  BRISBANE: 'Brisbane',
  CANBERRA: 'Canberra',
  CAIRNS: 'Cairns',
  DARWIN: 'Darwin',
  MELBOURNE: 'Melbourne',
  PERTH: 'Perth',
  SYDNEY: 'Sydney',
  TASTE_LOCAL_FOOD: 'Local Food',
  AMUSEMENT_PARK: 'Amusement Park',
  NATURAL_ATTRACTIONS: 'Natural Attractions',
  HISTORY_ATTRACTIONS: 'Historical Attractions',
  ALL_RECOMMENED_ATTRACTIONS_HAS_BEEN_SELECTED: 'All recommened attractions has been allocated',
  TIP_U_CAN_TURN_ON_THE_SAME_PLACES_ALLOWED_SWITCH_ABOVE_TO_ALLOW_YOURSELF_TO_SELECT_SAME_ATTRACTIONS_FOR_DIFFERENT_TRAVEL_DAYS: 'Tips: You can turn on the switch above to enable the function of choosing the same attractions on different travel days',
  SET_AS_START_POINT: 'Set as starting point',
  START_POINT_IS_SET_TO: 'The start point of the current trip day is set to',
  BOOKING: 'Booking',
  NO_PRODUCT_SELECTED: 'No Product Selected',
  EACH_DAY_CAN_CONTAINED_MAX_8_PLACES: 'Each trip day can only plan a maximum of 8 attractions',
  BACK_TO_PLANNING: 'Back to Planning',
  EXPORT_AS_PDF: 'Export as PDF',
}

export default {
  translation: {
    ...login,
    ...menu,
    ...footer,
    ...homePage,
    ...searchBar,
    ...productListPage,
    ...productDetailPage,
    ...checkOutPage,
    ...confirmationPage,
    ...cartPage,
    ...wishList,
    ...profile,
    ...verification,
    ...travelPlanningPage,
  }
};



