const login = {
  HI: '嗨',
  SIGN_IN: '登錄',
  SIGN_IN_NOW: '登錄',
  FORGOT_PASSWORD: '忘記密碼',
  USERNAME: '用戶名',
  PASSWORD: '密碼',
  CONFIRM_PASSWORD: '確認密碼',
  TWO_PASSWORDS_NOT_MATCH: '密碼不匹配',
  INCORRECT_USERNAME_PASSWORD: '用戶名或密碼錯誤',
  EMAIL: '郵箱',
  CONFIRM_EMAIL: '確認郵箱',
  TWO_EMAILS_NOT_MATCH: '郵箱不匹配',
  INVALID_EMAIL: '無效郵箱',
  SEND_VERIFICATION_EMAIL: '發送驗證郵件',
  VERIFICATION_EMAIL_SENT: '驗證郵件已發送',
  RESET_PASSWORD: '重置密碼',
  RESET_PASSWORD_FAILED: '重置密碼失敗',
  VERIFY_URL_LINK: '認證鏈接',
  SIGN_OUT: '登出',
  SIGN_OUT_CHECK: '確認登出',
  VALIDATING_USER_CREDENTIAL: '認證用戶信息中',
  BYE: '再見',
  SIGN_UP_SUCCESSFULLY: '申請成功，請前往您的郵箱激活您的賬號',
  AUTHENTICATION_EXPIRED: '用戶登錄狀態過期',
  LOGIN: '登錄',
  SIGN_UP: '申請賬戶',
  CHECKOUT_AS_GUEST: '以遊客身份結賬',
  DONT_HAVE_ACCOUNT: "還沒有TOUR61.com賬戶",
  CONTINUE_AS_GUEST_AND_CREATE_ACCOUNT_AFTERWARDS: "不用擔心！您可以先以遊客身份結賬付款後免費註冊一個新用戶",
  ACCOUNT_BENEFITS: '賬戶優勢',
  FAST_EZ_CHECK_OUT: '以更加快捷的方式進行結賬',
  COLLECT_REWARD_PTS: '收集您的獎勵積分',
  ACCESS_TO_ORDER_HISTORY: '回顧訂單記錄',
  CONTINUE_AS_GUEST: '以遊客身份繼續',
  CREATE_NEW_ACCOUNT_OR_LOGIN: '註冊/登錄',
  PLS_FILL_IN_ALL_FIELDS: '請確所有空位填寫完整',
  EMAIL_ALREADY_EXISTS_PLS_TRY_AGAIN: '郵箱已被使用，請重試',
}

const menu = {
  HOME: '主頁',
  TRAVEL_PLANNING: '路線規劃',
  ACTIVITIES: '門票',
  DAY_TRIPS: '一日遊',
  MULTI_DAY_TOURS: '多日遊',
  'MULTI_DAY-TOURS': '多日遊',
  LOGIN_REGISTER: '登錄/免費註冊',
  CART: '購物車',
  MY_ORDER: '我的訂單',
  WISHLIST: '收藏夾',
  HELLO: '你好',
  SAIL_TOGETHER: '讓我們一起揚帆起航。 ',
  TRIP_NEVER_FORGET: '您將擁有一次終生難忘的旅行。 ',
  SEARCH_BY_DEST_ATTRACTIONS_TOURS: '搜景點/目的地',
  USER_PROFILE: '賬戶信息',
}

const footer = {
  POLICY: "條款",
  COMPANY: "公司",
  CONTACT_US: "聯繫我們",
  PRIVACY_POLICY: "隱私條款",
  TERMS_CONDITIONS: "條款和條件",
  ABOUT_US: "關於我們",
  HOW_TO_FIND_US: "如何找到我們",
  TERMS_CONDITION: "條款和條件",
}

const homePage = {
  NEW_PROMOTION_LIST_DESCRIPTION: '去過悉尼，那你知道在悉尼可以親手餵鯊魚嗎？去過墨爾本，那你知道那裡有澳洲最大的滑雪場嗎？去過凱恩斯，那你知道...這裡專門給你藏在深巷裡的佳釀',
  ACTIVITY: '多快好省',
  MEANINGFUL_ACTIVITIES: '跟著買手撿便宜',
  MORE: '更多',
  POPULAR_LIST_DESCRIPTION: '澳洲那麼大，好玩的地方那麼多，漂亮的景色到處是。但是又懶得做攻略，那就跟著其他旅友/高手，看看他們都去哪兒',
  FRESHLY: '深藏不露',
  HAND_PICKED: '跟著達人玩點不一樣的',
  MORE_BANG: '高手林立',
  FOR_YOUR_BUCK: '跟著旅友挑好貨'
}

const searchBar = {
  WHERE_U_GO: '去哪兒',
  WUT_U_DO: '做些什麼',
  SEARCH_FOR: '搜索',
  NO_RESULT_FOUND: '未找到結果',
  TOUR_ACTIVITIES: '旅行與活動',

}

const productListPage = {
  PRODUCT_LIST: '產品列表',
  PRODUCT_ADDED_TO_WISHLIST: '添加到收藏夾',
  FILTER: '篩選',
  POPULAR_DESTINATION: '目標地點',
  CATEGORY: '類別',
  LANGUAGE: '語言',
  RESULTS_FOUND: '條結果',
  SHOW_MORE: '展開',
  SHOW_LESS: '折疊',
  SEARCH_PRODUCTS_IN_THIS_PAGE: '搜索當前頁面的產品',
  SEARCH_BY_PRODUCT_NAME: '搜索產品名稱',
  SEARCH_BY: '搜索',
  CLEAR: '清空',
}

const productDetailPage = {
  PRODUCT_DETAIL: '產品信息',
  PRODUCT_NOT_RELEASED: '此產品未推出/已經售罄',
  INSTANT_CONFIRM: '立即確認',
  STANDARD_INCLUSIONS: '費用包含',
  AVA: '可選',
  FROM: '',
  FROM_B: '起',
  TRAVEL_DATE: '旅行日期',
  DEPARTURE_DATE: '出發日期',
  CHECK_AVAILABILITY: '立即查詢',
  ADD_TO_CART: '加入購物車',
  KEY_FACTS: '產品概要',
  OVERVIEW: '概覽',
  YOTPO_REVIEWS: 'Yotpo評測',
  INCLUDED: '包含',
  EXCLUDED: '不包含',
  OPERATING_HOURS: '營業時間',
  OTHER_INFO: '其他信息',
  DESTINATION: '目的地',
  HOW_TO_GET_THERE: '如何前往',
  CHECK_IN_REQUIREMENT: '兌換要求',
  ITINERARY: '行程介紹',
  PRODUCT_HAS_ADDED_TO_YOUR_CART: '產品已加入購物車',
  INVALID_TICKET_QUANTITY_SELECTED: '無效售票數量',
  MIN_QTY: '最低數量',
  MAX_QTY: '最高數量',
  PROCEED_TO_CART: '前往購物車',
  YOUR_ITEM_HAS_BEEN_ADDED: '成功加入購物車',
  NO_TICKET_OPTIONS_AVA: '售票暫無開放',
  WHAT_TO_BRING_WEAR: '建議攜帶/穿戴',
  UNAVAILABLE: '不可用',
  ONLY: '只剩',
  LEFT: '张票',
  OPERATION_FAILED_PLS_TRY_AGAIN_OR_CONTACT_ADMIN: '操作失败，请稍后重试!',
  DEPARTURE_POINT: '出發地點',
  SELECTED_TRAVEL_DATE: '已选出发时间',
  NO_SELECTED: '还未选择出发时间',
}

const cartPage = {
  YOUR_CART_IS_EMPTY: '空的購物車',
  SHOPPING_CART: "購物車",
  DELETE_SELECTED_ACTIVITY: "刪除已選擇產品",
  TOTAL_COST: "合計",
  CONTINUE_SHOPPING: "繼續購物",
  PROCEED_TO_CHECKOUT: "去結賬",
  ORDER_TOTAL: '訂單總價',
  REVIEW_ORDER: '瀏覽訂單',
  SECURE_CHECKOUT: '加密付款',
  COMPLETED_ORDER: '完成訂單',
  ITEMS_REMOVED_FROM_CART: '個產品已從購物車移除',
  ALL: '全部',
  EXPIRED: '已过期',
}

const checkOutPage = {
  CHECK_OUT: '結賬',
  CONTACT_INFO_LEADER_TRAVELLER: '聯繫方式：主要參與人',
  GENDER: '性別',
  AREA_CODE: '國家/地區',
  NUMBER: '手機號碼',
  BOOKING_DETAILS: '訂單列表',
  SPECIAL_REQUIREMENT: '特殊要求',
  LEAD_TRAVELLER: '主要參與人',
  TRAVELLER: '參團人',
  FIRST_NAME: '名',
  SURNAME: '姓',
  MALE: '男性',
  FEMALE: '女性',
  PLS_PROVIDE_FULL_DETAILS_OF_EACH_PASSENGER: '請提供每名旅客的詳情信息',
  BONUS: '使用優惠碼/積分',
  PROMOTION_CODES: '優惠碼',
  APPLY: '使用',
  REWARD_POINTS: '獎勵積分',
  USE: '使用',
  WORTHING: '可兌換',
  MINIMUM_PAYMENT_POINT: '低於最低分數兌換限制',
  YOU_DONT_HAVE_ENOUGH_POINTS: "您沒有足夠的分數",
  REWARD_POINT_DISCOUNT: '獎勵積分折扣',
  PROMOTION_DISCOUNT: '優惠碼折扣',
  PROMOTION_CODE_INFO: '優惠碼信息',
  VALID_CODE: '有效優惠碼',
  INVALID_CODE: '無效優惠碼',
  PROMOTION_TYPE: '優惠方式',
  PAYMENT: '付款',
  CREDIT_CARD: '信用卡',
  POLI: 'POLi',
  PAY_NOW_PLACE_ORDER: '付款/完成訂單',
  SUMMARY: '訂單總結',
  READ_TERMS_CONDITION_POLICY: '點擊付款即代表您已經明確表示已經閱讀並接受享樂旅遊網的條款及細則，以及取消條款',
  PROCESSING_ORDER: '處理訂單中',
  WOULD_YOU_LIKE_TO_CREATE_AN_ACCOUNT: '同時建立賬戶？ ',
  YES: '是',
  NO: '否',
  AGREE_TO_SUBSCRIBE_TOUR61: '訂閱TOUR61促銷信息',
  DEPARTURE_TIME: '出發時間',
  DEPARTURE_FLIGHT_NO: '出發航班號',
  DEPARTURE_FLIGHT_TYPE: '出發航班類型',
  RETURN_DATE: '回程日期',
  RETURN_TIME: '回程時間',
  RETURN_FLIGHT_NO: '回程航班號',
  RETURN_FLIGHT_TYPE: '回程航班類型',
  DOMESTIC: '國內航班',
  INTERNATIONAL: '國際航班',
  ONE_OR_MORE_FIELDS_IN_CONTACT_INFO_SECTION_ARE_NOT_FILLED_CORRECTLY: '請確保聯繫方式填寫完整及正確',
  ONE_OR_MORE_FIELDS_IN_BOOKING_SECTION_ARE_NOT_FILLED_CORRECTLY: '請確保訂單列表填寫完整及正確',
  INVALID_EMAIL_FORMAT: '無效郵箱地址',
  WEIGHT: '體重',
  HEIGHT: '身高',
  PLS_SELECT_YOUR_PAYMENT_METHOD: '請選擇付款方式！ ',
  SELECT: '選擇',
  BOOKING_FIELDS: '預訂詳情',
  CANCEL_BONUS: '取消應用優惠碼/積分',
  INVALID_PROMOTION_CODE: '無效優惠碼',
  PROMOTION_CODE: '優惠碼',
  AMOUNT_YOU_EXCHANGED_EXCEEDS_THE_TOTAL_ORDER_PRICE: '您兌換的金額超過訂單總價',
}

const confirmationPage = {
  ORDER_CONFIRMATION: '訂單確認',
  CONFIRMATION: '訂單確認',
  THANK_YOU_FOR_UR_ORDER_FROM_TOUR61: '感謝您的訂單',
  YOUR_ORDER_NUM_IS: '您的訂單號為',
  YOUR_VOUCHER_WILL_BE_SENT_VIA_EMAIL: '稍後請前往您的電子郵箱查收確認憑證',
  QUANTITY: '數量',
  TOTAL: '總計',
  MANAGE_MY_BOOKINGS: '管理我的訂單',
  PAYMENT_CANCELLED: '付款取消',
  PAYMENT_FAILED: '付款失敗',
  CHECK_OUT_AGAIN: '再次結賬',
  DISCOUNT: '折扣',
  THIS_PAYMENT_WAS: '不好意思！此次付款',
  CANCELLED: '已被取消',
  FAILED: '失败',
  U_CAN_GO_TO_CART_AND_MAKING_PAYMENT_AGAIN: '请返回购物车并尝试重新付款',
  PLEASE_DONT_LEAVE_THE_PAGE: "請不要離開此頁面",
  PROCESSING_PAYMENT: '正在处理您的付款',
}

const wishList = {
  ADD_TO_WISHLIST: "添加到收藏夾",
  REMOVE_FROM_WISHLIST: "從收藏夾裡移除",
  LOGIN_REQUIRED: '需要登入用戶才能使用收藏功能',
  PRODUCT_REMOVED_FROM_WISHLIST: '產品已從收藏夾裡移除',
}

const profile = {
  FULL_NAME: '全名',
  PHONE: '電話',
  CHANGE_PASSWORD: "更改密碼",
  ACCOUNT: '賬戶',
  MY_ACCOUNT: '我的賬戶',
  NICKNAME: '暱稱',
  PLS_ENTER_NUM_ONLY_NO_OTHERS_ALLOWED: '此欄只接受數字, 不接受空格或符號。 ',
  NATIONALITY: '國籍',
  SAVE: '保存',
  BOOKINGS: '訂單',
  UPCOMING: '即將出發',
  HISTORY: '過往歷史',
  ORDER_NO: '訂單號',
  TRAVEL_DATE_PRODUCT: '出發日期/產品',
  ORDER_DATE: '訂單日期',
  MESSAGE: '消息',
  VIEW: '查看',
  ORDER_STATUS: '訂單狀態',
  REQUEST_CANCEL: '申請取消',
  PRODUCT: '產品',
  ENTER_YOUR_DESCRIPTION: '輸入詳情',
  CANCEL: '取消',
  SEND: '發送',
  NAME: '名稱',
  DETAILS: '詳情',
  BACK_TO_BOOKINGS: '返回訂單列表',
  NO_PRODUCTS_FOUND: '未發現產品',
  STATUS: '消息狀態',
  MARK: '標記狀態',
  TYPE: '類別',
  REPLIES: '回复',
  LAST_POST: '最後發送時間',
  NO_MESSAGES_FOUND: '無消息',
  YOUR_REWARD_PTS: '你的獎勵積分',
  EXPIRED_DATE: '有效日期',
  POINT_TRANSACTIONS: '積分交易記錄',
  SHOW: '展示',
  ENTRIES: '條數據',
  TRANSACTION_NO: '交易ID',
  SOURCE: '來源',
  SUBMIT_TIME: '提交時間',
  MAX_500_CHARS: '限500個字符',
  SENT_BY: '發送者',
  ORDER: '訂單',
  BACK_TO_MSG: '返回消息列表',
  UPDATE_SUCCESS: '更新成功',
  UPDATE_FAILED: '更新失敗，請稍後再試',
  REQUEST_SENT: '請求發送成功',
  REQUEST_FAILED: '請求發送失敗，請稍後再試',
  DATE: '日期',
  SEARCH_DATE_TYPE_SOURCE: '搜索日期 (01/01/2013)、類別 (Redeem)、來源(Product purchase)、以及訂單號(11111)',
  PRODUCT_NO: '產品號',
  SEARCH: '搜索',
  MSG_TYPE: '消息類別',
  INBOX: '收件箱',
  UNREAD: '未讀',
  READED: '已讀',
  NOT_MARKED: '未標記',
  MARKED: '已標記',
  YOUR_CURRENT_PASSWORD: '你的當前密碼',
  NEW_PASSWORD: '新密碼',
  UPDATE_NOW: '更新',
  PASSWORD_UPDATED: '密碼已更新',
  INCORRECT_CURRENT_PASSWORD: '當前密碼錯誤，請重試',
  PAID: '已付款',
  UNPAID: '未付款',
  REFUNDED: '已退款',
  FAILED: '付款失敗',
  OTHER_COUNTRIES_DISTRICT: '其他國家/地區',
  MSG_STATUS_TO: '消息已設置為',
  YOU: '你',
  CONTACT_DETAIL: '聯絡信息',
  ENQUIRY: '諮詢產品',
  CREATE_YOUR_ACCOUNT: '創建您的賬戶',
}

const verification = {
  EMAIL_VERIFICATION: '郵箱驗證',
  VALIDATING_YOUR_EMAIL: '正在驗證您的郵箱',
  THANK_YOU_YOUR_EMAIL_VALIDATED: '恭喜！您的郵箱已驗證成功！ ',
  NOW_U_CAN_LOGIN_TO_YOUR_ACCOUNT: '你現在可以成功登入您的賬戶',
  FAILED_TO_VALIDATE_EMAIL_PLS_TRY_AGAIN: '郵箱驗證失敗，請刷新頁面重試！ ',
  GO_HOME: '返回主頁',
  TRY_AGAIN: '重試',
  A_TEMP_PW_HAS_SENT_TO_YOUR_EMAIL: '您的臨時登入密碼已發送到您的郵箱',
  PLS_PICK_ONE_OF_THE_OPTION_BELOW_AND_FILL_IN_THE_FIELDS: '請在以下選項裡選擇一項來填寫您的賬戶有關信息',
  OPTION_1_ENTER_FIRST_NAME_SURNAME: '選項1：填寫您的賬戶姓名',
  OPTION_2_ENTER_YOUR_MOBILE: '選項2：填寫您的賬戶電話號碼',
  SEND_VERIFICATION_EMAIL: '發送驗證',
  RESET_PASSWORD_VERIFICATION_EMAIL_SENT: '重置密碼驗證郵件已發送',
  EMAIL_SENT_FAILED_PLS_CHECK_YOUR_INPUT_TRY_AGAIN: '驗證郵件發送失敗，請再次檢查您的填寫信息並重試',
}

const travelPlanningPage = {
  PRODUCE_YOUR_UNIQUE_PLAN_USING_OUR_TRAVEL_PLANNING: '使用我們的旅行計劃系統來創建您自己的獨特計劃',
  PLAN_AUTOMATION: '計劃自動化',
  FULL_CUSTOMISED_TRAVEL_PLAN: '高度自定義的旅行計劃',
  CLEAR_ROUTE_PLANNER: '提供準確的路線規劃',
  BACK_TO_PREVIOUS_TRIP_PLANNING: '返回之前的旅行計劃',
  WHAT_IS_YOUR_TRAVELLING_PURPOSES: '您旅行的目的是什麼',
  WHICH_CTS_YOU_ARE_LOOKING_TO_TRAVEL: '選擇一個城市來開啟您的旅行',
  HOW_MANY_TRIP_DAYS_YOUR_PLAN_FOR_MAX_14_DAYS: '您計劃的旅行天數（最多 14 天）',
  WHATS_YOUR_TRAVEL_DATE: '您的旅行日期',
  WHAT_IS_THE_MINUMUM_ATTRACTIONS_THAT_YOU_WANT_TO_VISIT: '請提供您要參觀最少景點數量是多少',
  WHAT_AREAS_YOU_ARE_MOSTLY_INTERESTED_ON: '在旅行中您最感興趣的領域是什麼（可多選）',
  TIP_SELECT_THE_INTERESTED_AREAS_AND_CLICK_ADD_BUTTON_ADDED_TO_THE_BELOW_AREA: '提示:選擇感興趣的區域並點擊添加按鈕添加到下方的確認區域',
  DAYS: '天',
  ATTRACTIONS: '個景點',
  NO_INTERESTED_AREA_SELECTED: '未選擇感興趣的區域',
  PLAN_THIS_TRIP: '計劃這次旅行',
  BACK_TO_YOUR_PLAN: '返回上一步',
  STEP_1_ADD_RECOMMENED_PLACES_TO_YOUR_TRIP: '將此列表中的推薦地點添加到右側的當天旅遊計劃中',
  STEP_2_EDIT_YOUR_TRIP: '編輯您這一天的旅行計劃',
  STEP_1: '第1步',
  STEP_2: '第2步',
  ADD_A_NEW_TRIP_DAY: '添加新的旅行日',
  DELETE_CURRENT_DAY: '刪除當前旅行日',
  REVERT_TRIP: '還原至初始計劃',
  ARE_YOU_SURE_IN_DELETING_THIS_TRIP_DAY: '您確定要刪除這一天的旅行安排嗎？請注意此操作將清除此計劃的預訂歷史',
  ARE_YOUR_SURE_TO_REVERT_YOUR_PLANNING_TO_DEFAULT: '您確定要將當前計劃恢復為初始計劃嗎？請注意此操作將清除此計劃的預訂歷史',
  REVERT_SUCCESS: '還原成功',
  DELETE_NOW: '立即刪除',
  REVERT_NOW: '立即恢復',
  NEW_EMPTY_TRIP_DAY: '此旅行日當前無任何安排',
  BOOKED: '已預訂',
  SET_UR_START_POINT: '設置您這一天旅行的起點',
  ROUTE_PLANNER: '路線規劃師',
  WANT_TO_BOOK: '想預訂',
  GENERATING_RECOMMENED_ROUTE: '生成推薦路線',
  TRAVEL_BY: '旅行',
  DRIVING: '駕車',
  ROUTE_SUMMARY: '路線總結',
  YOU_NEED_ADD_AT_LEAST_TWO_LOCATIONS_TO_VIEW_THE_ROUTE_FOR_CURRENT_TRIP_DAY: '您需要至少添加兩個景點才能看到當前旅行日的路線',
  ATTRACTION_ADDED: '已添加景點',
  ATTRACTION_REMOVED: "景點已移除",
  NEW_TRIP_DAY_ADDED: '添加了新的旅行日',
  TRIP_DAY_DELETE: '刪除旅行日',
  DAY_A: '第',
  DAY_B: '天',
  SAME_PLACES_ALLOWED: '允許為不同的旅行日選擇相同的景點',
  START_POINT: '起點',
  TRIP_OVERVIEW: '行程概覽',
  TALK_ABOUT_YOUR_PLAN: "談談您的計劃",
  PLAN_YOUR_TRIP_DAYS: '計劃您的旅行',
  MAKE_BOOKING: '開始預訂',
  START: '開始',
  TOTAL_DISTANCE: '總距離',
  DURATION: "行程時間",
  WAYPOINT: '航點',
  END: '結束',
  YOUR_TRIP_SUMMARY: '您的旅行計劃總結',
  LOADING: '加載中',
  STEP_1_TRAVEL_DATE: '第 1 步 - 選擇旅行日期',
  STEP_2_PRICE: '第 2 步 - 選擇價格選項',
  SELECTED_TRAVEL_DATE: '選定的旅行日期',
  NO_SELECTED: '未選擇旅行日期',
  BOOK: '預訂',
  GENERATING_SUMMARY: '總結生成中',
  GENERATING_YOUR_PERSONAL_TRAVEL_PLAN: '生成您的個人旅行計劃',
  PLEASE_BE_PATIENT: '請耐心等待',
  NOTE_THE_NUMBER_OF_TRIP_DAYS_COULD_AFFECT_GENERATING_SPEED: '注意：當您計劃的旅行天數超過7天后將可能會影響計劃生成速度',
  STEP_3_SELECT_A_PRODUCT_FROM_THE_SCHEDULED_PLACES_AND_ADD_TO_CART: '第 2 步：根據您的日程安排推薦的產品如下所示。請隨意挑選您感興趣的任何產品並將它們添加到您的購物車',
  NO_AVAILABLE_PRICE_OPTIONS_PLEASE_RETRY_WITH_ANOTHER_DATE: '當前日期的票暫不支持訂購，請通過選擇不同的旅行日期來重新獲取其他價格選項',
  FAMILY_TRIP: '家庭旅行',
  BUSINESS_TRIP: '商務旅行',
  HONEY_MOON: '蜜月旅行',
  ADELAIDE: '阿德萊德',
  BRISBANE: '布里斯班',
  CANBERRA: '堪培拉',
  CAIRNS: '凱恩斯',
  DARWIN: '達爾文',
  MELBOURNE: '墨爾本',
  PERTH: '珀斯',
  SYDNEY: '悉尼',
  TASTE_LOCAL_FOOD: '當地美食',
  AMUSEMENT_PARK: '遊樂園',
  NATURAL_ATTRACTIONS: '自然景點',
  HISTORY_ATTRACTIONS: '歷史名勝',
  ALL_RECOMMENED_ATTRACTIONS_HAS_BEEN_SELECTED: '所有推薦景點都已完成安排',
  TIP_U_CAN_TURN_ON_THE_SAME_PLACES_ALLOWED_SWITCH_ABOVE_TO_ALLOW_YOURSELF_TO_SELECT_SAME_ATTRACTIONS_FOR_DIFFERENT_TRAVEL_DAYS: '提示：您可嘗試打開上方開關以開啟景點多選功能。通過開啟此功能將允許相同景點可被安排進不同旅行日。',
  SET_AS_START_POINT: '設為行程起點',
  START_POINT_IS_SET_TO: '當天行程的起點已設置為',
  BOOKING: '預定',
  NO_PRODUCT_SELECTED: '未選擇任何產品',
  EACH_DAY_CAN_CONTAINED_MAX_8_PLACES: '每個行程日最多只能規劃8個景點',
  BACK_TO_PLANNING: '返回至行程安排頁面',
  EXPORT_AS_PDF: '通過PDF導出',
}

export default {
  translation: {
    ...login,
    ...menu,
    ...footer,
    ...homePage,
    ...searchBar,
    ...productListPage,
    ...productDetailPage,
    ...checkOutPage,
    ...confirmationPage,
    ...cartPage,
    ...wishList,
    ...profile,
    ...verification,
    ...travelPlanningPage,
  }
};