exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-cart-check-out-tsx": () => import("./../../../src/pages/cart/check-out.tsx" /* webpackChunkName: "component---src-pages-cart-check-out-tsx" */),
  "component---src-pages-cart-confirmation-tsx": () => import("./../../../src/pages/cart/confirmation.tsx" /* webpackChunkName: "component---src-pages-cart-confirmation-tsx" */),
  "component---src-pages-cart-index-tsx": () => import("./../../../src/pages/cart/index.tsx" /* webpackChunkName: "component---src-pages-cart-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-product-detail-tsx": () => import("./../../../src/pages/product-detail.tsx" /* webpackChunkName: "component---src-pages-product-detail-tsx" */),
  "component---src-pages-product-list-tsx": () => import("./../../../src/pages/product-list.tsx" /* webpackChunkName: "component---src-pages-product-list-tsx" */),
  "component---src-pages-products-activities-tsx": () => import("./../../../src/pages/products/activities.tsx" /* webpackChunkName: "component---src-pages-products-activities-tsx" */),
  "component---src-pages-products-day-trips-tsx": () => import("./../../../src/pages/products/day-trips.tsx" /* webpackChunkName: "component---src-pages-products-day-trips-tsx" */),
  "component---src-pages-products-multi-day-tours-tsx": () => import("./../../../src/pages/products/multi-day-tours.tsx" /* webpackChunkName: "component---src-pages-products-multi-day-tours-tsx" */),
  "component---src-pages-travel-planning-booking-list-tsx": () => import("./../../../src/pages/travel-planning/booking-list.tsx" /* webpackChunkName: "component---src-pages-travel-planning-booking-list-tsx" */),
  "component---src-pages-travel-planning-index-tsx": () => import("./../../../src/pages/travel-planning/index.tsx" /* webpackChunkName: "component---src-pages-travel-planning-index-tsx" */),
  "component---src-pages-travel-planning-trip-days-tsx": () => import("./../../../src/pages/travel-planning/trip-days.tsx" /* webpackChunkName: "component---src-pages-travel-planning-trip-days-tsx" */),
  "component---src-pages-travel-planning-trip-view-tsx": () => import("./../../../src/pages/travel-planning/trip-view.tsx" /* webpackChunkName: "component---src-pages-travel-planning-trip-view-tsx" */),
  "component---src-pages-user-profile-tsx": () => import("./../../../src/pages/user-profile.tsx" /* webpackChunkName: "component---src-pages-user-profile-tsx" */),
  "component---src-pages-verification-email-verification-tsx": () => import("./../../../src/pages/verification/email-verification.tsx" /* webpackChunkName: "component---src-pages-verification-email-verification-tsx" */),
  "component---src-pages-verification-forget-password-tsx": () => import("./../../../src/pages/verification/forget-password.tsx" /* webpackChunkName: "component---src-pages-verification-forget-password-tsx" */),
  "component---src-pages-wishlist-tsx": () => import("./../../../src/pages/wishlist.tsx" /* webpackChunkName: "component---src-pages-wishlist-tsx" */)
}

