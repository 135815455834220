/* eslint-disable no-unused-vars */
import axios from 'axios';
import {
  action, computed, observable, runInAction, toJS, makeObservable,
} from 'mobx';
import { Gender, Language } from '../constants/options';
import { OrderContactInfo } from '../utilities/check-out';
import { Axios } from '../utilities/network';
import { OpenAIAxios } from '../utilities/openAI-network';
import RootStore from './root-store';

export default class BookingStore {

  loading: boolean = false;

  loadingPayment: boolean = false;

  loadingSummaryList: boolean = false;

  replyingChatGPT: boolean = false;

  routing: boolean = false;

  bookingField: any = [];

  promotionCodeInfo: any | null = null;

  routePlanner: any = [];

  contactInfoData: OrderContactInfo = {
    firstName: "",
    surname: "",
    gender: Gender.NOT_SELECTED,
    email: "",
    phoneArea: "",
    phone: "",
    createAccount: false,
    subscribe: true,
    password: "",
  }
  bookingFieldData: any | null = [];

  // For identify the error position in booking field
  bookingFieldViewPositions: any | null = [];
  bookingFieldIdx: number = -1;
  // End Identify error position

  usedPromotionCode: string = "";
  usedRewardPoint: number = 0;

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      loading: observable,
      loadingPayment: observable,
      loadingSummaryList: observable,
      routing: observable,
      bookingField: observable,
      promotionCodeInfo: observable,
      contactInfoData: observable,
      bookingFieldData: observable,
      bookingFieldIdx: observable,
      bookingFieldViewPositions: observable,
      usedPromotionCode: observable,
      usedRewardPoint: observable,
      routePlanner: observable,
      replyingChatGPT: observable,
      getBookingField: action,
      getPromotionDetail: action,
      createWebOrderAndGetPaymentUrl: action,
      placeOrder: action,
      getBookingDetailByOrderStr: action,
      runRoutePlanner: action,
      sendMessageToChatGPT: action,
    });

    this.rootStore = rootStore;
  }

  getBookingField = async (lang: Language, reqModel: any) => {
    this.bookingField = [];
    this.promotionCodeInfo = null;
    this.usedPromotionCode = "";
    this.usedRewardPoint = 0;
    this.loading = true;
    this.bookingFieldData = [];
    try {
      const res = await Axios.post(`/api/Booking/GetBookingField/${lang}`, reqModel);
      this.bookingField = res.data;
      console.log("Booking Detail", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  getPromotionDetail = async (promotionCode: string, userId: number) => {
    this.promotionCodeInfo = null;
    this.loading = true;
    try {
      const res = await Axios.get(`/api/Booking/GetPromotionDetail?promotionCode=${promotionCode}&userId=${userId}`);
      this.promotionCodeInfo = res.data;
      if (this.promotionCodeInfo.isValid) this.usedPromotionCode = promotionCode;
      else this.usedPromotionCode = "";
      console.log("Promotion Code", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  createWebOrderAndGetPaymentUrl = async (reqModel: any) => {
    this.loadingPayment = true;
    try {
      const res = await Axios.post(`/api/Booking/CreateWebOrderAndGetPaymentUrl`, reqModel);
      console.log("Web Order Payment Url", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingPayment = false;
    }
  }

  placeOrder = async (reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/Booking/PlaceOrder`, reqModel);
      console.log("Place order", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  getBookingDetailByOrderStr = async (orderStr: string) => {
    this.loadingSummaryList = true;
    try {
      const res = await Axios.get(`/api/Booking/GetBookingDetailByOrderStr?str=${orderStr}`);
      console.log("Summary list", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingSummaryList = false;
    }
  }

  runRoutePlanner = async (reqModel: any) => {
    this.routePlanner = [];
    this.routing = true;
    try {
      const res = await Axios.post(`/api/VMRoutePlanner/RunARoutePlanner`, reqModel);
      let result = Object.keys(res.data).map((key) => res.data[key]);
      this.routePlanner = result;
      console.log("Route Planner", res.data, result);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.routing = false;
    }
  }

  sendMessageToChatGPT = async (reqModel: any) => {
    this.replyingChatGPT = true;
    try {
      const res = await OpenAIAxios.post("https://api.openai.com/v1/chat/completions", reqModel);
      console.log("Route Planner", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.replyingChatGPT = false;
    }
  }

  getRoutingPDF = async (reqModel: any) => {
    this.routing = true;
    try {
      const res = await Axios.post(`/api/VMRoutePlanner/GetRoutingPDF`, reqModel, { responseType: 'blob' });
      const fileLink = new Blob([res.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(fileLink);
      window.open(fileURL);
      console.log("Route Planner", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.routing = false;
    }
  }
}
