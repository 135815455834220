import { useTranslation } from "react-i18next";
import useStores from "../hooks/use-stores";

export const PAGE_SIZE = 8;
export const MAX_PLAN_DAYS_LIMIT = 14;
export const API_PRODUCT_METHOD = 2;
export const MAX_PLACES_PER_DAY = 8;

export enum Language {
  ENGLISH = 'en-AU',
  ZH_CN = 'zh-CN',
  ZH_TW = 'zh-TW',
  ZH_HK = 'zh-HK',
}

export enum ContentType {
  Promotion = "promotion",
  Popular = "popular",
  New = "new",
  Banner = "banner",
}

// Search Result
export enum SearchResultType {
  REGION = "region",
  TAG = "tag",
  PRODUCT = "product",
}

export enum SearchCategory {
  REGION = "Region",
  TAG = "Tag",
  LANGUAGE = "Language",
  PRODUCT_TYPE = "ProductType",
}

export enum ProductType {
  ACTIVITY = "activity",
  DAY_TRIP = "day-trip",
  MULTI_DAY = "multi-day",
}

// End Search Result

export enum ListLayoutType {
  PRODUCT_ONLY = "product only",
  SUPPLIER_PKG = "supplier package",
}

export enum FilterType {
  REGION = "Region",
  TAG = "Tag",
  LANG = "Language"
}

// User Profile
export enum ProfileSectionType {
  ACCOUNT = "Account",
  BOOKINGS = "Bookings",
  WISHLIST = "Wishlist",
  MESSAGE = "Message",
  POINTS = "Points",
  FAMILY_MEMBER = "Family Member",
}

// Booking Field
export enum Gender {
  MALE = 0,
  FEMALE = 1,
  NOT_SELECTED = 2,
}

export enum TravellerFieldType {
  FIRST_NAME = "firstname",
  SURNAME = "surname",
  GENDER = "gender",
  DOB = "dob",
  HEIGHT = "height",
  WEIGHT = "weight",
}

export enum FlightInfoFieldType {
  DEPARTURE_DATE = "departureDate",
  DEPARTURE_TIME = "depatureTime",
  DEPARTURE_FLIGHT_NO = "depatureFlightNo",
  DEPATURE_FLIGHT_TYPE = "depatureFlightType",
  RETURN_DATE = "returnDate",
  RETURN_TIME = "returnDepartureTime",
  RETURN_FLIGHT_NO = "returnDepartureFlightNo",
  RETURN_FLIGHT_TYPE = "returnDepartureFlightType",
}

export enum FlightType {
  DOMESTIC = 0,
  INTERNATIONAL = 1,
}

// Bonus
export enum BonusType {
  PROMOTION_CODE = "PromotionCode",
  REWARD_PT = "RewardPoint",
  NOT_SELECTED = "NotSelected",
}

export enum PromotionCodeType {
  DISCOUNT = "Discount",
  AMOUNT = "Amount",
}

export enum PromotionCodeMessage {
  INVALID = "INVALID_PROMOTION_CODE",
  VALID = "VALID_CODE"
}

// Payment
export enum PaymentType {
  NOT_SELECTED = "NotSelected",
  CREDIT_CARD = "square",
  POLI = "poli",
  PAYPAL = "paypal",
}

// Order Status
export enum OrderStatus {
  SUCCESS = "success",
  FAIL = "fail",
  CANCEL = "cancel",
  NOTIFICATION = "notification"
}
export enum OrderStatusInIDs {
  UNPAID = 0,
  PAID = 1,
  REFUNDED = 2,
  FAILED = 3,
}
export const OrderStatusCoversion = [
  { id: OrderStatusInIDs.UNPAID, status: 'UNPAID' },
  { id: OrderStatusInIDs.PAID, status: 'PAID' },
  { id: OrderStatusInIDs.REFUNDED, status: 'REFUNDED' },
  { id: OrderStatusInIDs.FAILED, status: 'FAILED' },
]
// Nationality
export const Nationality = [
  { label: "Australia", value: "Australia" },
  { label: "Mainland China", value: "China" },
  { label: "Hong Kong", value: "Hong Kong" },
  { label: "Macau", value: "Macau" },
  { label: "Taiwan", value: "Taiwan" },
  { label: "Japan", value: "Japan" },
  { label: "North Korea", value: "North Korea" },
  { label: "South Korea", value: "South Korea" },
  { label: "Japan", value: "Japan" },
  { label: "", value: "" },
  { label: "Algeria", value: "Algeria" },
  { label: "Albania", value: "Albania" },
  { label: "Afghanistan", value: "Afghanistan" },
  { label: "AmericanSamoa", value: "AmericanSamoa" },
  { label: "Andorra", value: "Andorra" },
  { label: "Angola", value: "Angola" },
  { label: "Anguilla", value: "Anguilla" },
  { label: "Antigua &amp; Barbuda", value: "Antigua &amp; Barbuda" },
  { label: "Argentina", value: "Argentina" },
  { label: "Armenia", value: "Armenia" },
  { label: "Aruba", value: "Aruba" },
  { label: "Austria", value: "Austria" },
  { label: "Azerbaijan", value: "Azerbaijan" },
  { label: "Bahamas", value: "Bahamas" },
  { label: "Bahrain", value: "Bahrain" },
  { label: "Bangladesh", value: "Bangladesh" },
  { label: "Barbados", value: "Barbados" },
  { label: "Belarus", value: "Belarus" },
  { label: "Belgium", value: "Belgium" },
  { label: "Belize", value: "Belize" },
  { label: "Benin", value: "Benin" },
  { label: "Bermuda", value: "Bermuda" },
  { label: "Bhutan", value: "Bhutan" },
  { label: "Bolivia", value: "Bolivia" },
  { label: "Bosnia Herzegovina", value: "Bosnia Herzegovina" },
  { label: "Botswana", value: "Botswana" },
  { label: "Brazil", value: "Brazil" },
  { label: "Brunei", value: "Brunei" },
  { label: "Bulgaria", value: "Bulgaria" },
  { label: "Burkina Faso", value: "Burkina Faso" },
  { label: "Burundi", value: "Burundi" },
  { label: "Cambodia", value: "Cambodia" },
  { label: "Cameroon", value: "Cameroon" },
  { label: "Cape Verde Islands", value: "Cape Verde Islands" },
  { label: "Cayman Islands", value: "Cayman Islands" },
  { label: "Canada", value: "Canada" },
  { label: "Central African Republic", value: "Central African Republic" },
  { label: "Chile", value: "Chile" },
  { label: "Chad", value: "Chad" },
  { label: "Colombia", value: "Colombia" },
  { label: "Comoros", value: "Comoros" },
  { label: "Congo", value: "Congo" },
  { label: "Cook Islands", value: "Cook Islands" },
  { label: "Costa Rica", value: "Costa Rica" },
  { label: "CotedIvoire", value: "CotedIvoire" },
  { label: "Croatia", value: "Croatia" },
  { label: "Cuba", value: "Cuba" },
  { label: "Cyprus North", value: "Cyprus North" },
  { label: "Czech Republic", value: "Cyprus South" },
  { label: "Denmark", value: "Denmark" },
  { label: "Djibouti", value: "Djibouti" },
  { label: "Dominica", value: "Dominica" },
  { label: "Dominican Republic", value: "Dominican Republic" },
  { label: "EastTimor", value: "EastTimor" },
  { label: "Ecuador", value: "Ecuador" },
  { label: "Egypt", value: "Egypt" },
  { label: "El Salvador", value: "El Salvador" },
  { label: "Equatorial Guinea", value: "Equatorial Guinea" },
  { label: "Eritrea", value: "Eritrea" },
  { label: "Estonia", value: "Estonia" },
  { label: "Ethiopia", value: "Ethiopia" },
  { label: "Falkland Islands", value: "Falkland Islands" },
  { label: "Faroe Islands", value: "Faroe Islands" },
  { label: "Fiji", value: "Fiji" },
  { label: "Finland", value: "Finland" },
  { label: "France", value: "France" },
  { label: "French Guiana", value: "French Guiana" },
  { label: "French Polynesia", value: "French Polynesia" },
  { label: "Gabon", value: "Gabon" },
  { label: "Gambia", value: "Gambia" },
  { label: "Georgia", value: "Georgia" },
  { label: "Germany", value: "Germany" },
  { label: "Ghana", value: "Ghana" },
  { label: "Gibraltar", value: "Gibraltar" },
  { label: "Greece", value: "Greece" },
  { label: "Greenland", value: "Greenland" },
  { label: "Grenada", value: "Grenada" },
  { label: "Guadeloupe", value: "Guadeloupe" },
  { label: "Guam", value: "Guam" },
  { label: "Guatemala", value: "Guatemala" },
  { label: "Guinea", value: "Guinea" },
  { label: "Guinea - Bissau", value: "Guinea - Bissau" },
  { label: "Guyana", value: "Guyana" },
  { label: "Haiti", value: "Haiti" },
  { label: "Honduras", value: "Honduras" },
  { label: "Hungary", value: "Hungary" },
  { label: "Iceland", value: "Iceland" },
  { label: "India", value: "India" },
  { label: "Indonesia", value: "Indonesia" },
  { label: "Iran", value: "Iran" },
  { label: "Iraq", value: "Iraq" },
  { label: "Ireland", value: "Ireland" },
  { label: "Israel", value: "Israel" },
  { label: "Italy", value: "Italy" },
  { label: "Jamaica", value: "Jamaica" },
  { label: "Jordan", value: "Jordan" },
  { label: "Kazakhstan", value: "Kazakhstan" },
  { label: "Kenya", value: "Kenya" },
  { label: "Kiribati", value: "Kiribati" },
  { label: "Kuwait", value: "Kuwait" },
  { label: "Kyrgyzstan", value: "Kyrgyzstan" },
  { label: "Laos", value: "Laos" },
  { label: "Latvia", value: "Latvia" },
  { label: "Lebanon", value: "Lebanon" },
  { label: "Lesotho", value: "Lesotho" },
  { label: "Liberia", value: "Liberia" },
  { label: "Libya", value: "Libya" },
  { label: "Liechtenstein", value: "Liechtenstein" },
  { label: "Lithuania", value: "Lithuania" },
  { label: "Luxembourg", value: "Luxembourg" },
  { label: "Macedonia", value: "Macedonia" },
  { label: "Madagascar", value: "Madagascar" },
  { label: "Malawi", value: "Malawi" },
  { label: "Malaysia", value: "Malaysia" },
  { label: "Maldives", value: "Maldives" },
  { label: "Mali", value: "Mali" },
  { label: "Malta", value: "Malta" },
  { label: "Marshall Islands", value: "Marshall Islands" },
  { label: "Martinique", value: "Martinique" },
  { label: "Mauritania", value: "Mauritania" },
  { label: "Mayotte", value: "Mayotte" },
  { label: "Mauritius", value: "Mauritius" },
  { label: "Mexico", value: "Mexico" },
  { label: "Micronesia", value: "Micronesia" },
  { label: "Moldova", value: "Moldova" },
  { label: "Monaco", value: "Monaco" },
  { label: "Mongolia", value: "Mongolia" },
  { label: "Montserrat", value: "Montserrat" },
  { label: "Morocco", value: "Morocco" },
  { label: "Mozambique", value: "Mozambique" },
  { label: "Myanmar", value: "Myanmar" },
  { label: "Namibia", value: "Namibia" },
  { label: "Nauru", value: "Nauru" },
  { label: "Nepal", value: "Nepal" },
  { label: "Netherlands", value: "Netherlands" },
  { label: "NetherlandsAntilles", value: "NetherlandsAntilles" },
  { label: "New Caledonia", value: "New Caledonia" },
  { label: "New Zealand", value: "New Zealand" },
  { label: "Nicaragua", value: "Nicaragua" },
  { label: "Niger", value: "Niger" },
  { label: "Nigeria", value: "Nigeria" },
  { label: "Niue", value: "Niue" },
  { label: "Norfolk Islands", value: "Norfolk Islands" },
  { label: "Northern Marianas", value: "Northern Marianas" },
  { label: "Norway", value: "Norway" },
  { label: "Oman", value: "Oman" },
  { label: "Palau", value: "Palau" },
  { label: "Palestine", value: "Palestine" },
  { label: "Panama", value: "Panama" },
  { label: "Papua New Guinea", value: "Papua New Guinea" },
  { label: "Paraguay", value: "Paraguay" },
  { label: "Peru", value: "Peru" },
  { label: "Pakistan", value: "Pakistan" },
  { label: "Philippines", value: "Philippines" },
  { label: "Poland", value: "Poland" },
  { label: "Portugal", value: "Portugal" },
  { label: "Puerto Rico", value: "Puerto Rico" },
  { label: "Qatar", value: "Qatar" },
  { label: "Reunion", value: "Reunion" },
  { label: "Romania", value: "Romania" },
  { label: "Russia", value: "Russia" },
  { label: "Rwanda", value: "Rwanda" },
  { label: "San Marino", value: "San Marino" },
  { label: "Sao Tome &amp; Principe", value: "Sao Tome &amp; Principe" },
  { label: "Saudi Arabia", value: "Saudi Arabia" },
  { label: "Senegal", value: "Senegal" },
  { label: "Serbia", value: "Serbia" },
  { label: "Seychelles", value: "Seychelles" },
  { label: "Sierra Leone", value: "Sierra Leone" },
  { label: "Slovak Republic", value: "Slovak Republic" },
  { label: "Slovenia", value: "Slovenia" },
  { label: "Solomon Islands", value: "Solomon Islands" },
  { label: "Somalia", value: "Somalia" },
  { label: "South Africa", value: "South Africa" },
  { label: "Spain", value: "Spain" },
  { label: "Sri Lanka", value: "Sri Lanka" },
  { label: "St. Helena", value: "St. Helena" },
  { label: "St. Kitts", value: "St. Kitts" },
  { label: "St. Lucia", value: "St. Lucia" },
  { label: "Sudan", value: "Sudan" },
  { label: "Suriname", value: "Suriname" },
  { label: "Swaziland", value: "Swaziland" },
  { label: "Sweden", value: "Sweden" },
  { label: "Switzerland", value: "Switzerland" },
  { label: "Syria", value: "Syria" },
  { label: "Tajikstan", value: "Tajikstan" },
  { label: "Tanzania", value: "Tanzania" },
  { label: "Togo", value: "Togo" },
  { label: "Tonga", value: "Tonga" },
  { label: "Trinidad &amp; Tobago", value: "Trinidad &amp; Tobago" },
  { label: "Tunisia", value: "Tunisia" },
  { label: "Turkey", value: "Turkey" },
  { label: "Turkmenistan", value: "Turkmenistan" },
  { label: "Turks &amp; Caicos Islands", value: "Turks &amp; Caicos Islands" },
  { label: "Tuvalu", value: "Tuvalu" },
  { label: "Tuvalu", value: "Syria" },
  { label: "Ukraine", value: "Ukraine" },
  { label: "United Arab Emirates", value: "United Arab Emirates" },
  { label: "Uruguay", value: "Uruguay" },
  { label: "Uzbekistan", value: "Uzbekistan" },
  { label: "Vanuatu", value: "Vanuatu" },
  { label: "Vatican City", value: "Vatican City" },
  { label: "Venezuela", value: "Venezuela" },
  { label: "Virgin Islands - British", value: "Virgin Islands - British" },
  { label: "Virgin Islands - US", value: "Virgin Islands - US" },
  { label: "Wallis &amp; Futuna", value: "Wallis &amp; Futuna" },
  { label: "Yemen", value: "Yemen" },
  { label: "Zambia", value: "Zambia" },
  { label: "Zimbabwe", value: "Zimbabwe" },
]


export const Nationality_zh_CN = [
  { label: "澳大利亚", value: "Australia" },
  { label: "中国大陆", value: "China" },
  { label: "香港", value: "Hong Kong" },
  { label: "澳门", value: "Macau" },
  { label: "台湾", value: "Taiwan" },
  { label: "日本", value: "Japan" },
  { label: "朝鲜", value: "North Korea" },
  { label: "韩国", value: "South Korea" },
  { label: "日本", value: "Japan" },
  { label: "", value: "" },
  { label: "阿尔及利亚", value: "Algeria" },
  { label: "阿尔巴尼亚", value: "Albania" },
  { label: "阿富汗", value: "Afghanistan" },
  { label: "美属萨摩亚", value: "AmericanSamoa" },
  { label: "安道尔", value: "Andorra" },
  { label: "安哥拉", value: "Angola" },
  { label: "安圭拉", value: "Anguilla" },
  { label: "安提瓜岛巴布达", value: "Antigua &amp; Barbuda" },
  { label: "阿根廷", value: "Argentina" },
  { label: "亚美尼亚", value: "Armenia" },
  { label: "阿鲁巴岛", value: "Aruba" },
  { label: "奥地利", value: "Austria" },
  { label: "阿塞拜疆", value: "Azerbaijan" },
  { label: "巴哈马", value: "Bahamas" },
  { label: "巴林", value: "Bahrain" },
  { label: "孟加拉国", value: "Bangladesh" },
  { label: "巴巴多斯", value: "Barbados" },
  { label: "白俄罗斯", value: "Belarus" },
  { label: "比利时", value: "Belgium" },
  { label: "伯利兹", value: "Belize" },
  { label: "贝宁", value: "Benin" },
  { label: "百慕大", value: "Bermuda" },
  { label: "不丹", value: "Bhutan" },
  { label: "玻利维亚", value: "Bolivia" },
  { label: "波斯尼亚黑塞哥维那", value: "Bosnia Herzegovina" },
  { label: "博茨瓦纳", value: "Botswana" },
  { label: "巴西", value: "Brazil" },
  { label: "文莱", value: "Brunei" },
  { label: "保加利亚", value: "Bulgaria" },
  { label: "布基纳法索", value: "Burkina Faso" },
  { label: "布隆迪", value: "Burundi" },
  { label: "柬埔寨", value: "Cambodia" },
  { label: "喀麦隆", value: "Cameroon" },
  { label: "佛得角群岛", value: "Cape Verde Islands" },
  { label: "开曼群岛", value: "Cayman Islands" },
  { label: "加拿大", value: "Canada" },
  { label: "中非共和国", value: "Central African Republic" },
  { label: "智利", value: "Chile" },
  { label: "乍得", value: "Chad" },
  { label: "哥伦比亚", value: "Colombia" },
  { label: "科摩罗", value: "Comoros" },
  { label: "刚果", value: "Congo" },
  { label: "库克群岛", value: "Cook Islands" },
  { label: "哥斯达黎加", value: "Costa Rica" },
  { label: "科特迪瓦", value: "CotedIvoire" },
  { label: "克罗地亚", value: "Croatia" },
  { label: "古巴", value: "Cuba" },
  { label: "北塞浦路斯", value: "Cyprus North" },
  { label: "捷克共和国", value: "Cyprus South" },
  { label: "丹麦", value: "Denmark" },
  { label: "吉布提", value: "Djibouti" },
  { label: "多米尼克", value: "Dominica" },
  { label: "多明尼加共和国", value: "Dominican Republic" },
  { label: "东帝汶", value: "EastTimor" },
  { label: "厄瓜多尔", value: "Ecuador" },
  { label: "埃及", value: "Egypt" },
  { label: "萨尔瓦多", value: "El Salvador" },
  { label: "赤道几内亚", value: "Equatorial Guinea" },
  { label: "厄立特里亚", value: "Eritrea" },
  { label: "爱沙尼亚", value: "Estonia" },
  { label: "埃塞俄比亚", value: "Ethiopia" },
  { label: "福克兰群岛", value: "Falkland Islands" },
  { label: "法罗群岛", value: "Faroe Islands" },
  { label: "斐济", value: "Fiji" },
  { label: "芬兰", value: "Finland" },
  { label: "法国", value: "France" },
  { label: "法属圭亚那", value: "French Guiana" },
  { label: "法属波利尼西亚", value: "French Polynesia" },
  { label: "加蓬", value: "Gabon" },
  { label: "冈比亚", value: "Gambia" },
  { label: "乔治亚州", value: "Georgia" },
  { label: "德国", value: "Germany" },
  { label: "加纳", value: "Ghana" },
  { label: "直布罗陀", value: "Gibraltar" },
  { label: "希腊", value: "Greece" },
  { label: "格陵兰", value: "Greenland" },
  { label: "格林纳达", value: "Grenada" },
  { label: "瓜德罗普岛", value: "Guadeloupe" },
  { label: "关岛", value: "Guam" },
  { label: "危地马拉", value: "Guatemala" },
  { label: "几内亚", value: "Guinea" },
  { label: "几内亚 - 比绍", value: "Guinea - Bissau" },
  { label: "圭亚那", value: "Guyana" },
  { label: "海地", value: "Haiti" },
  { label: "洪都拉斯", value: "Honduras" },
  { label: "匈牙利", value: "Hungary" },
  { label: "冰岛", value: "Iceland" },
  { label: "印度", value: "India" },
  { label: "印度尼西亚", value: "Indonesia" },
  { label: "伊朗", value: "Iran" },
  { label: "伊拉克", value: "Iraq" },
  { label: "爱尔兰", value: "Ireland" },
  { label: "以色列", value: "Israel" },
  { label: "意大利", value: "Italy" },
  { label: "牙买加", value: "Jamaica" },
  { label: "约旦", value: "Jordan" },
  { label: "哈萨克斯坦", value: "Kazakhstan" },
  { label: "肯尼亚", value: "Kenya" },
  { label: "基里巴斯", value: "Kiribati" },
  { label: "科威特", value: "Kuwait" },
  { label: "吉尔吉斯斯坦", value: "Kyrgyzstan" },
  { label: "老挝", value: "Laos" },
  { label: "拉脱维亚", value: "Latvia" },
  { label: "黎巴嫩", value: "Lebanon" },
  { label: "莱索托", value: "Lesotho" },
  { label: "利比里亚", value: "Liberia" },
  { label: "利比亚", value: "Libya" },
  { label: "列支敦士登", value: "Liechtenstein" },
  { label: "立陶宛", value: "Lithuania" },
  { label: "卢森堡", value: "Luxembourg" },
  { label: "马其顿", value: "Macedonia" },
  { label: "马达加斯加", value: "Madagascar" },
  { label: "马拉维", value: "Malawi" },
  { label: "马来西亚", value: "Malaysia" },
  { label: "马尔代夫", value: "Maldives" },
  { label: "马里", value: "Mali" },
  { label: "马耳他", value: "Malta" },
  { label: "马绍尔群岛", value: "Marshall Islands" },
  { label: "马提尼克岛", value: "Martinique" },
  { label: "毛里塔尼亚", value: "Mauritania" },
  { label: "马约特岛", value: "Mayotte" },
  { label: "毛里求斯", value: "Mauritius" },
  { label: "墨西哥", value: "Mexico" },
  { label: "密克罗尼西亚", value: "Micronesia" },
  { label: "摩尔多瓦", value: "Moldova" },
  { label: "摩纳哥", value: "Monaco" },
  { label: "蒙古", value: "Mongolia" },
  { label: "蒙特塞拉特", value: "Montserrat" },
  { label: "摩洛哥", value: "Morocco" },
  { label: "莫桑比克", value: "Mozambique" },
  { label: "缅甸", value: "Myanmar" },
  { label: "纳米比亚", value: "Namibia" },
  { label: "瑙鲁", value: "Nauru" },
  { label: "尼泊尔", value: "Nepal" },
  { label: "荷兰", value: "Netherlands" },
  { label: "荷属安的列斯", value: "NetherlandsAntilles" },
  { label: "新喀里多尼亚", value: "New Caledonia" },
  { label: "新西兰", value: "New Zealand" },
  { label: "尼加拉瓜", value: "Nicaragua" },
  { label: "尼日尔", value: "Niger" },
  { label: "尼日利亚", value: "Nigeria" },
  { label: "纽埃", value: "Niue" },
  { label: "诺福克群岛", value: "Norfolk Islands" },
  { label: "北马里亚纳群岛", value: "Northern Marianas" },
  { label: "挪威", value: "Norway" },
  { label: "阿曼", value: "Oman" },
  { label: "帕劳", value: "Palau" },
  { label: "巴勒斯坦", value: "Palestine" },
  { label: "巴拿马", value: "Panama" },
  { label: "巴布亚新几内亚", value: "Papua New Guinea" },
  { label: "巴拉圭", value: "Paraguay" },
  { label: "秘鲁", value: "Peru" },
  { label: "巴基斯坦", value: "Pakistan" },
  { label: "菲律宾", value: "Philippines" },
  { label: "波兰", value: "Poland" },
  { label: "葡萄牙", value: "Portugal" },
  { label: "波多黎各", value: "Puerto Rico" },
  { label: "卡塔尔", value: "Qatar" },
  { label: "团圆", value: "Reunion" },
  { label: "罗马尼亚", value: "Romania" },
  { label: "俄罗斯", value: "Russia" },
  { label: "卢旺达", value: "Rwanda" },
  { label: "圣马力诺", value: "San Marino" },
  { label: "圣多美普林西比", value: "Sao Tome &amp; Principe" },
  { label: "沙特阿拉伯", value: "Saudi Arabia" },
  { label: "塞内加尔", value: "Senegal" },
  { label: "塞尔维亚", value: "Serbia" },
  { label: "塞舌尔", value: "Seychelles" },
  { label: "塞拉利昂", value: "Sierra Leone" },
  { label: "斯洛伐克共和国", value: "Slovak Republic" },
  { label: "斯洛文尼亚", value: "Slovenia" },
  { label: "所罗门群岛", value: "Solomon Islands" },
  { label: "索马里", value: "Somalia" },
  { label: "南非", value: "South Africa" },
  { label: "西班牙", value: "Spain" },
  { label: "斯里兰卡", value: "Sri Lanka" },
  { label: "圣海伦娜", value: "St. Helena" },
  { label: "圣基茨", value: "St. Kitts" },
  { label: "圣卢西亚", value: "St. Lucia" },
  { label: "苏丹", value: "Sudan" },
  { label: "苏里南", value: "Suriname" },
  { label: "斯威士兰", value: "Swaziland" },
  { label: "瑞典", value: "Sweden" },
  { label: "瑞士", value: "Switzerland" },
  { label: "叙利亚", value: "Syria" },
  { label: "塔吉克斯坦", value: "Tajikstan" },
  { label: "坦桑尼亚", value: "Tanzania" },
  { label: "多哥", value: "Togo" },
  { label: "汤加", value: "Tonga" },
  { label: "特立尼达多巴哥", value: "Trinidad &amp; Tobago" },
  { label: "突尼斯", value: "Tunisia" },
  { label: "土耳其", value: "Turkey" },
  { label: "土库曼斯坦", value: "Turkmenistan" },
  { label: "土耳其人凯科斯群岛", value: "Turks &amp; Caicos Islands" },
  { label: "图瓦卢", value: "Tuvalu" },
  { label: "图瓦卢", value: "Syria" },
  { label: "乌克兰", value: "Ukraine" },
  { label: "阿拉伯联合酋长国", value: "United Arab Emirates" },
  { label: "乌拉圭", value: "Uruguay" },
  { label: "乌兹别克斯坦", value: "Uzbekistan" },
  { label: "瓦努阿图", value: "Vanuatu" },
  { label: "梵蒂冈城", value: "Vatican City" },
  { label: "委内瑞拉", value: "Venezuela" },
  { label: "维尔京群岛 - 英属", value: "Virgin Islands - British" },
  { label: "维尔京群岛 - 美国", value: "Virgin Islands - US" },
  { label: "沃利斯富图纳群岛", value: "Wallis &amp; Futuna" },
  { label: "也门", value: "Yemen" },
  { label: "赞比亚", value: "Zambia" },
  { label: "津巴布韦", value: "Zimbabwe" },
]

export const Nationality_zh_TW = [
  { label:"澳大利亞",value: "Australia" },
  { label:"中國大陸",value: "China" },
  { label:"香港",value: "Hong Kong" },
  { label:"澳門",value: "Macau" },
  { label:"台灣",value: "Taiwan" },
  { label:"日本",value: "Japan" },
  { label:"北朝鮮",value: "North Korea" },
  { label:"韓國",value: "South Korea" },
  { label:"日本",value: "Japan" },
  { label:"",value: "" },
  { label:"阿爾及利亞",value: "Algeria" },
  { label:"阿爾巴尼亞",value: "Albania" },
  { label:"阿富汗",value: "Afghanistan" },
  { label:"美屬薩摩亞",value: "AmericanSamoa" },
  { label:"安道爾",value: "Andorra" },
  { label:"安哥拉",value: "Angola" },
  { label:"安圭拉",value: "Anguilla" },
  { label:"安提瓜島巴布達",value: "Antigua &amp; Barbuda" },
  { label:"阿根廷",value: "Argentina" },
  { label:"亞美尼亞",value: "Armenia" },
  { label:"阿魯巴島",value: "Aruba" },
  { label:"奧地利",value: "Austria" },
  { label:"阿塞拜疆",value: "Azerbaijan" },
  { label:"巴哈馬",value: "Bahamas" },
  { label:"巴林",value: "Bahrain" },
  { label:"孟加拉國",value: "Bangladesh" },
  { label:"巴巴多斯",value: "Barbados" },
  { label:"白俄羅斯",value: "Belarus" },
  { label:"比利時",value: "Belgium" },
  { label:"伯利茲",value: "Belize" },
  { label:"貝寧",value: "Benin" },
  { label:"百慕大",value: "Bermuda" },
  { label:"不丹",value: "Bhutan" },
  { label:"玻利維亞",value: "Bolivia" },
  { label:"波斯尼亞黑塞哥維那",value: "Bosnia Herzegovina" },
  { label:"博茨瓦納",value: "Botswana" },
  { label:"巴西",value: "Brazil" },
  { label:"文萊",value: "Brunei" },
  { label:"保加利亞",value: "Bulgaria" },
  { label:"布基納法索",value: "Burkina Faso" },
  { label:"布隆迪",value: "Burundi" },
  { label:"柬埔寨",value: "Cambodia" },
  { label:"喀麥隆",value: "Cameroon" },
  { label:"佛得角群島",value: "Cape Verde Islands" },
  { label:"開曼群島",value: "Cayman Islands" },
  { label:"加拿大",value: "Canada" },
  { label:"中非共和國",value: "Central African Republic" },
  { label:"智利",value: "Chile" },
  { label:"乍得",value: "Chad" },
  { label:"哥倫比亞",value: "Colombia" },
  { label:"科摩羅",value: "Comoros" },
  { label:"剛果",value: "Congo" },
  { label:"庫克群島",value: "Cook Islands" },
  { label:"哥斯達黎加",value: "Costa Rica" },
  { label:"科特迪瓦",value: "CotedIvoire" },
  { label:"克羅地亞",value: "Croatia" },
  { label:"古巴",value: "Cuba" },
  { label:"北塞浦路斯",value: "Cyprus North" },
  { label:"捷克共和國",value: "Cyprus South" },
  { label:"丹麥",value: "Denmark" },
  { label:"吉布提",value: "Djibouti" },
  { label:"多米尼克",value: "Dominica" },
  { label:"多明尼加共和國",value: "Dominican Republic" },
  { label:"東帝汶",value: "EastTimor" },
  { label:"厄瓜多爾",value: "Ecuador" },
  { label:"埃及",value: "Egypt" },
  { label:"薩爾瓦多",value: "El Salvador" },
  { label:"赤道幾內亞",value: "Equatorial Guinea" },
  { label:"厄立特里亞",value: "Eritrea" },
  { label:"愛沙尼亞",value: "Estonia" },
  { label:"埃塞俄比亞",value: "Ethiopia" },
  { label:"福克蘭群島",value: "Falkland Islands" },
  { label:"法羅群島",value: "Faroe Islands" },
  { label:"斐濟",value: "Fiji" },
  { label:"芬蘭",value: "Finland" },
  { label:"法國",value: "France" },
  { label:"法屬圭亞那",value: "French Guiana" },
  { label:"法屬波利尼西亞",value: "French Polynesia" },
  { label:"加蓬",value: "Gabon" },
  { label:"岡比亞",value: "Gambia" },
  { label:"喬治亞州",value: "Georgia" },
  { label:"德國",value: "Germany" },
  { label:"加納",value: "Ghana" },
  { label:"直布羅陀",value: "Gibraltar" },
  { label:"希臘",value: "Greece" },
  { label:"格陵蘭",value: "Greenland" },
  { label:"格林納達",value: "Grenada" },
  { label:"瓜德羅普島",value: "Guadeloupe" },
  { label:"關島",value: "Guam" },
  { label:"危地馬拉",value: "Guatemala" },
  { label:"幾內亞",value: "Guinea" },
  { label:"幾內亞 - 比紹",value: "Guinea - Bissau" },
  { label:"圭亞那",value: "Guyana" },
  { label:"海地",value: "Haiti" },
  { label:"洪都拉斯",value: "Honduras" },
  { label:"匈牙利",value: "Hungary" },
  { label:"冰島",value: "Iceland" },
  { label:"印度",value: "India" },
  { label:"印度尼西亞",value: "Indonesia" },
  { label:"伊朗",value: "Iran" },
  { label:"伊拉克",value: "Iraq" },
  { label:"愛爾蘭",value: "Ireland" },
  { label:"以色列",value: "Israel" },
  { label:"意大利",value: "Italy" },
  { label:"牙買加",value: "Jamaica" },
  { label:"約旦",value: "Jordan" },
  { label:"哈薩克斯坦",value: "Kazakhstan" },
  { label:"肯尼亞",value: "Kenya" },
  { label:"基里巴斯",value: "Kiribati" },
  { label:"科威特",value: "Kuwait" },
  { label:"吉爾吉斯斯坦",value: "Kyrgyzstan" },
  { label:"老撾",value: "Laos" },
  { label:"拉脫維亞",value: "Latvia" },
  { label:"黎巴嫩",value: "Lebanon" },
  { label:"萊索托",value: "Lesotho" },
  { label:"利比里亞",value: "Liberia" },
  { label:"利比亞",value: "Libya" },
  { label:"列支敦士登",value: "Liechtenstein" },
  { label:"立陶宛",value: "Lithuania" },
  { label:"盧森堡",value: "Luxembourg" },
  { label:"馬其頓",value: "Macedonia" },
  { label:"馬達加斯加",value: "Madagascar" },
  { label:"馬拉維",value: "Malawi" },
  { label:"馬來西亞",value: "Malaysia" },
  { label:"馬爾代夫",value: "Maldives" },
  { label:"馬里",value: "Mali" },
  { label:"馬耳他",value: "Malta" },
  { label:"馬紹爾群島",value: "Marshall Islands" },
  { label:"馬提尼克島",value: "Martinique" },
  { label:"毛里塔尼亞",value: "Mauritania" },
  { label:"馬約特島",value: "Mayotte" },
  { label:"毛里求斯",value: "Mauritius" },
  { label:"墨西哥",value: "Mexico" },
  { label:"密克羅尼西亞",value: "Micronesia" },
  { label:"摩爾多瓦",value: "Moldova" },
  { label:"摩納哥",value: "Monaco" },
  { label:"蒙古",value: "Mongolia" },
  { label:"蒙特塞拉特",value: "Montserrat" },
  { label:"摩洛哥",value: "Morocco" },
  { label:"莫桑比克",value: "Mozambique" },
  { label:"緬甸",value: "Myanmar" },
  { label:"納米比亞",value: "Namibia" },
  { label:"瑙魯",value: "Nauru" },
  { label:"尼泊爾",value: "Nepal" },
  { label:"荷蘭",value: "Netherlands" },
  { label:"荷屬安的列斯",value: "NetherlandsAntilles" },
  { label:"新喀裡多尼亞",value: "New Caledonia" },
  { label:"新西蘭",value: "New Zealand" },
  { label:"尼加拉瓜",value: "Nicaragua" },
  { label:"尼日爾",value: "Niger" },
  { label:"尼日利亞",value: "Nigeria" },
  { label:"紐埃",value: "Niue" },
  { label:"諾福克群島",value: "Norfolk Islands" },
  { label:"北馬里亞納群島",value: "Northern Marianas" },
  { label:"挪威",value: "Norway" },
  { label:"阿曼",value: "Oman" },
  { label:"帕勞",value: "Palau" },
  { label:"巴勒斯坦",value: "Palestine" },
  { label:"巴拿馬",value: "Panama" },
  { label:"巴布亞新幾內亞",value: "Papua New Guinea" },
  { label:"巴拉圭",value: "Paraguay" },
  { label:"秘魯",value: "Peru" },
  { label:"巴基斯坦",value: "Pakistan" },
  { label:"菲律賓",value: "Philippines" },
  { label:"波蘭",value: "Poland" },
  { label:"葡萄牙",value: "Portugal" },
  { label:"波多黎各",value: "Puerto Rico" },
  { label:"卡塔爾",value: "Qatar" },
  { label:"團圓",value: "Reunion" },
  { label:"羅馬尼亞",value: "Romania" },
  { label:"俄羅斯",value: "Russia" },
  { label:"盧旺達",value: "Rwanda" },
  { label:"聖馬力諾",value: "San Marino" },
  { label:"聖多美普林西比",value: "Sao Tome &amp; Principe" },
  { label:"沙特阿拉伯",value: "Saudi Arabia" },
  { label:"塞內加爾",value: "Senegal" },
  { label:"塞爾維亞",value: "Serbia" },
  { label:"塞舌爾",value: "Seychelles" },
  { label:"塞拉利昂",value: "Sierra Leone" },
  { label:"斯洛伐克共和國",value: "Slovak Republic" },
  { label:"斯洛文尼亞",value: "Slovenia" },
  { label:"所羅門群島",value: "Solomon Islands" },
  { label:"索馬里",value: "Somalia" },
  { label:"南非",value: "South Africa" },
  { label:"西班牙",value: "Spain" },
  { label:"斯里蘭卡",value: "Sri Lanka" },
  { label:"聖海倫娜",value: "St. Helena" },
  { label:"聖基茨",value: "St. Kitts" },
  { label:"聖盧西亞",value: "St. Lucia" },
  { label:"蘇丹",value: "Sudan" },
  { label:"蘇里南",value: "Suriname" },
  { label:"斯威士蘭",value: "Swaziland" },
  { label:"瑞典",value: "Sweden" },
  { label:"瑞士",value: "Switzerland" },
  { label:"敘利亞",value: "Syria" },
  { label:"塔吉克斯坦",value: "Tajikstan" },
  { label:"坦桑尼亞",value: "Tanzania" },
  { label:"多哥",value: "Togo" },
  { label:"湯加",value: "Tonga" },
  { label:"特立尼達多巴哥",value: "Trinidad &amp; Tobago" },
  { label:"突尼斯",value: "Tunisia" },
  { label:"火雞",value: "Turkey" },
  { label:"土庫曼斯坦",value: "Turkmenistan" },
  { label:"土耳其人凱科斯群島",value: "Turks &amp; Caicos Islands" },
  { label:"圖瓦盧",value: "Tuvalu" },
  { label:"圖瓦盧",value: "Syria" },
  { label:"烏克蘭",value: "Ukraine" },
  { label:"阿拉伯聯合酋長國",value: "United Arab Emirates" },
  { label:"烏拉圭",value: "Uruguay" },
  { label:"烏茲別克斯坦",value: "Uzbekistan" },
  { label:"瓦努阿圖",value: "Vanuatu" },
  { label:"梵蒂岡城",value: "Vatican City" },
  { label:"委內瑞拉",value: "Venezuela" },
  { label:"維爾京群島 - 英屬",value: "Virgin Islands - British" },
  { label:"維爾京群島 - 美國",value: "Virgin Islands - US" },
  { label:"沃利斯富圖納群島",value: "Wallis &amp; Futuna" },
  { label:"也門",value: "Yemen" },
  { label:"贊比亞",value: "Zambia" },
  { label:"津巴布韋",value: "Zimbabwe" },
]