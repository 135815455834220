/* eslint-disable no-unused-vars */
import { t } from 'i18next';
import {
  action, computed, observable, runInAction, toJS, makeObservable,
} from 'mobx';
import { Gender, Language } from '../constants/options';
import useStores from '../hooks/use-stores';
import { Axios } from '../utilities/network';
import { isNative, isWeb } from '../utilities/platform';
import RootStore from './root-store';

interface RewardPoint {
  minimumPaymentPoint: number,
  pointDollarRate: number,
  totalPoint: number,
}

export default class UserStore {
  get isAuthed() {
    return isWeb && localStorage.USER_TOKEN != null && localStorage.USER_INFO != null && localStorage.WISHLIST != null;
  }

  set isAuthed(value) {
    console.log('Deleting token...');
    if (!value && isWeb) {
      delete localStorage.USER_TOKEN;
      delete localStorage.USER_INFO;
      delete localStorage.WISHLIST;
    }
  }

  userToken: string | null = null;

  loading: boolean = false;

  loadingBookings: boolean = false;

  markingMsg: boolean = false;

  sendingRequest: boolean = false;

  updatingPassword: boolean = false;

  rewardPoint: RewardPoint = { minimumPaymentPoint: 0, pointDollarRate: 0, totalPoint: 0 };

  // Wishlist
  processingWishList: boolean = false;
  wishlist: any = [];
  // End WishList

  // Profile
  memberList: any = [];
  rewardPtList: any = [];
  rewardTransactionList: any = [];
  bookingList: any = { currentList: [], unpaidList: [], historyList: [] };
  msgList: any = { orderMessages: [], productMessasges: [] };

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      isAuthed: computed,
      loading: observable,
      loadingBookings: observable,
      markingMsg: observable,
      updatingPassword: observable,
      sendingRequest: observable,
      userToken: observable,
      rewardPoint: observable,
      rewardTransactionList: observable,
      processingWishList: observable,
      wishlist: observable,
      memberList: observable,
      rewardPtList: observable,
      bookingList: observable,
      msgList: observable,
      signIn: action,
      signOut: action,
      sendVerificationEmail: action,
      resetPassword: action,
      signUp: action,
      updateCustomerInfo: action,
      getWishList: action,
      addWishListItemByProductId: action,
      getRewardPointTotal: action,
      getFamilyMemberList: action,
      getRewardPointList: action,
      getBookingDetailByOrderId: action,
      getBookingList: action,
      getMessages: action,
      getRewardPointTransaction: action,
      getMessageDetailsByMessageId: action,
      newOrderEnquiry: action,
      newProductEnquiry: action,
      replyEnquiry: action,
      setImportantByMessageItemId: action,
    });
    this.rootStore = rootStore;
  }


  signIn = async (userModel: any) => {
    const { bookingStore } = useStores();
    bookingStore.contactInfoData = {
      firstName: "",
      surname: "",
      gender: Gender.NOT_SELECTED,
      email: "",
      phoneArea: "",
      phone: "",
      createAccount: false,
      subscribe: true,
      password: ""
    }
    this.loading = true;
    try {
      const res = await Axios.post(`/api/Customer/LogIn`, userModel);
      const token = `Bearer ${res.data.token}`;
      const userResponseData = res.data;
      console.log(res.data);
      this.userToken = userResponseData.token;
      localStorage.USER_TOKEN = token;
      let userInfo = {
        id: userResponseData.id,
        firstName: userResponseData.firstname,
        lastName: userResponseData.surname,
        nickname: userResponseData.nickname,
        phone: userResponseData.phone,
        phoneArea: userResponseData.phoneArea,
        email: userResponseData.email,
        gender: userResponseData.gender,
        nationality: userResponseData.nationality,
        subscribe: userResponseData.subscribe,
      }
      localStorage.USER_INFO = JSON.stringify(userInfo);
      localStorage.WISHLIST = JSON.stringify(userResponseData.wishListIdList);
      delete localStorage.CART_LIST;
      return Promise.resolve(res.data);
    } catch (err) {
      this.isAuthed = false;
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  signOut = () => {
    this.isAuthed = false;
    this.userToken = null;
    window.location.href = '/';
    this.rootStore.notify(t('BYE'), "success");
  };

  signUp = async (userModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/Customer/SignUp`, userModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  emailVerify = async (token: string) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/Customer/EmailVerify?token=${token}`);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  sendVerificationEmail = async (reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/Customer/SendVerificationEmail/`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  sendResetPasswordEmail = async (reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/Customer/SendResetPasswordEmail/`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  resetPassword = async (token: string) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/Customer/ResetPassword?token=${token}`);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }


  getRewardPointTotal = async () => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/Booking/GetRewardPointTotal`);
      this.rewardPoint = res.data;
      console.log("Reward Point", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  // ========= Wishlist ========== //
  getWishList = async (lang: Language) => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/WishList/GetWishListProductList/${lang}`);
      this.wishlist = res.data;
      console.log("Wishlist", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  addWishListItemByProductId = async (productId: number) => {
    this.processingWishList = true;
    try {
      const res = await Axios.post(`/api/WishList/AddWishListItemByProductId?productId=${productId}`);
      localStorage.WISHLIST = JSON.stringify(res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.processingWishList = false;
    }
  }

  deleteWishListItemByProductId = async (productId: number) => {
    this.processingWishList = true;
    try {
      const res = await Axios.put(`/api/WishList/DeleteWishListItemByProductId?productId=${productId}`);
      localStorage.WISHLIST = JSON.stringify(res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.processingWishList = false;
    }
  }
  // ========== End Wishlist ========= //
  // ========== Profile ========== //
  getFamilyMemberList = async () => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/WishList/GetFamilyMemberList`);
      this.memberList = res.data;
      console.log("Memeber List", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  deleteFamilyMemberByIndex = async () => {
    this.loading = true;
    try {
      const res = await Axios.put(`/api/WishList/DeleteFamilyMmberByIndex`);
      this.memberList = res.data;
      console.log("Memeber List", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  getRewardPointList = async () => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/Booking/GetRewardPointList`);
      this.rewardPtList = res.data;
      console.log("Reward Point List", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  getRewardPointTransaction = async () => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/Booking/GetRewardPointTransaction`);
      this.rewardTransactionList = res.data;
      console.log("Reward Transaction List", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  getBookingList = async () => {
    this.loadingBookings = true;
    try {
      const res = await Axios.get(`/api/Booking/GetBookingList`);
      this.bookingList = res.data;
      console.log("Booking List", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingBookings = false;
    }
  }

  getBookingDetailByOrderId = async (id: number) => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/Booking/GetBookingDetailByOrderId?orderId=${id}`);
      console.log("Booking Detail", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  updateCustomerInfo = async (reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/Customer/UpdateCustomerInfo`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  updatePassword = async (reqModel: any) => {
    this.updatingPassword = true;
    try {
      const res = await Axios.post(`/api/Customer/UpdatePassword`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.updatingPassword = false;
    }
  }

  // ======= End Profile ======== //
  // ======= Message ============ //
  getMessages = async () => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/Message/GetMessages`);
      this.msgList = res.data;
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  setImportantByMessageItemId = async (messageId: number, type: "Order" | "Product") => {
    this.markingMsg = true;
    try {
      const res = await Axios.post(`/api/Message/SetImportantByMessageItemId?webMessageId=${messageId}`);
      if(type === "Order") this.msgList.orderMessages.find((o: any) => o.id == messageId).isImportant = !this.msgList.orderMessages.find((o: any) => o.id == messageId).isImportant;
      else this.msgList.productMessages.find((o: any) => o.id == messageId).isImportant = !this.msgList.productMessages.find((o: any) => o.id == messageId).isImportant
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.markingMsg = false;
    }
  }
  getMessageDetailsByMessageId = async (messageId: number) => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/Message/GetMessageDetailsByMessageId?messageId=${messageId}`);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  newOrderEnquiry = async (reqModel: any) => {
    this.sendingRequest = true;
    try {
      const res = await Axios.post(`/api/Message/newOrderEnquiry`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.sendingRequest = false;
    }
  }

  newProductEnquiry = async (reqModel: any) => {
    this.sendingRequest = true;
    try {
      const res = await Axios.post(`/api/Message/newProductEnquiry`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.sendingRequest = false;
    }
  }

  replyEnquiry = async (reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/Message/ReplyEnquiry`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  // ======= End Message ======== //
}
