import { t } from "i18next";
import { Language } from "../constants/options";
import useStores from "../hooks/use-stores";
import { isWeb } from "./platform";

export const IsAuthed = () => {
  return isWeb && localStorage.USER_TOKEN != null && localStorage.USER_INFO != null && localStorage.WISHLIST != null;
}

export const GetLanguage = () => {
  return (isWeb && localStorage.LANG) ? localStorage.LANG : Language.ENGLISH;
}

export const GetLanguageLabel = () => {
  return (isWeb && localStorage.LANG) ? localStorage.LANG == Language.ENGLISH ? 'English'
    : localStorage.LANG == Language.ZH_HK ? '繁體中文 (香港)'
      : localStorage.LANG == Language.ZH_TW ? '繁體中文 (台灣)'
        : '简体中文' : 'English'
}

export const ConvertPathNameToLabel = (pathName: any) => {
  let pathList = pathName.split('/');
  if (pathList.length > 0) {
    // console.log("pathname", pathName, pathName.split('/'), pathList[pathList.length - 2].replace('-', '_').toUpperCase())
    if (pathList[pathList.length - 1] === "") return t(pathList[pathList.length - 2].replace('-', '_').toUpperCase());
    else return t(pathName.split('/').pop().replace('-', '_').toUpperCase());
  }
  return t('HOME')
}

export const GetProperDateTime = (dateStr: string) => {
  return dateStr ? dateStr.split('T')[0] + ' ' + dateStr.split('T')[1] : "";
}

export const ProdEnvCheck = (dataList: any) => {
  if (typeof window !== 'undefined') {
    for (var data of dataList) if (!data) return false;
    return true;
  }
  return false;
}

